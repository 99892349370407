import { FormHelperText, makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

PasswordField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
    label : {
        transform: 'translate(14px, 13px) scale(1)',
    }
}));

function PasswordField(props) {

    const { form, name, label, disabled } = props;
    const { formState: { errors } } = form;
    const hasError = !!errors[name];

    const [showPassword, setShowPassword] = useState(false);

    const classes = useStyles();

    const handleClickShowPassword = () => {
        setShowPassword((x) => !x);
    };

    return (
        <FormControl error={hasError} fullWidth margin="normal" variant="outlined">
            <InputLabel htmlFor={name} className={classes.label}>{label}</InputLabel>
            <Controller
                name={name}
                control={form.control}
                render={({ field: { onChange, onBlur, value, name } }) => (
                <OutlinedInput 
                    id={name}
                    type={showPassword ? 'text' : 'password'}
                    label={label}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    margin="dense"
                />
                )}
            />
            <FormHelperText error={hasError} id="component-helper-text">{errors[name]?.message}</FormHelperText>
        </FormControl>
    );
}

export default PasswordField;