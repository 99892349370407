import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import authApi from '../../api/authApi';
import { isAdmin, logout } from '../../util/authService';

DrawerItem.propTypes = {
  isToggle : PropTypes.bool,
  onClose : PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
}));

function DrawerItem(props) {

  const isSelected = (route) => {
    const match = useRouteMatch(route);

    return !!match && match.isExact;
  };

  const { isToggle, onClose } = props;

  const classes = useStyles();
  const MATCH_FRANCHISE = isSelected('/franchise/new');
  const MATCH_ADMINISTRATION = isSelected('/administration');
  const MATCH_PROFILE = isSelected('/profil');
  const MATCH_HOME = isSelected('/');

  const rightAdmin = isAdmin();

  const handleLogout = async () => {
    try {
        await authApi.logout();
    } catch (error) {
        console.log('Failed logout', error.message);
    }
    logout();
  };

  return (
    <div>
        <div className={classes.toolbar} />
        <Divider />
          <List>
            <ListItem selected={MATCH_HOME} button key={'Home'} component={Link} to="/" onClick={onClose}>
              <ListItemIcon><HomeOutlinedIcon fontSize="small" /></ListItemIcon>
              <ListItemText primary={ <Typography variant="body2">Accueil</Typography> } />
            </ListItem>
           
              <ListItem selected={MATCH_FRANCHISE} button key={'List'} component={Link} to="/franchise/new" onClick={onClose} >
                <ListItemIcon><TableChartOutlinedIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={ <Typography variant="body2">Nouveau bien</Typography> } />
              </ListItem>
            
          </List>
          {
            (rightAdmin || isToggle) && <>
              <Divider />
              <List>
                {
                  rightAdmin && <>
                    <ListItem selected={MATCH_ADMINISTRATION} button key={'Settings'} component={Link} to="/administration" onClick={onClose}>
                      <ListItemIcon><SettingsOutlinedIcon fontSize="small" /></ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">Administration</Typography> } />
                    </ListItem>
                  </>
                }
                {
                  isToggle && <>
                    <ListItem selected={MATCH_PROFILE} button key={'Profile'} component={Link} to="/profil" onClick={onClose}>
                      <ListItemIcon><AccountCircleOutlinedIcon fontSize="small" /></ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">Mon Profile</Typography> } />
                    </ListItem>
                    <ListItem button key={'Disconnect'} onClick={handleLogout}>
                      <ListItemIcon><ExitToAppOutlinedIcon fontSize="small" /></ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">Déconnexion</Typography> } />
                    </ListItem>
                  </>
                }
              </List>
            </>
          }
        </div>
  );
}

export default DrawerItem;