import { makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

NumberField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    size : PropTypes.string,
    variant : PropTypes.string,
    prefix : PropTypes.string,
    type : PropTypes.string,

    onChange: PropTypes.func
};

const useStyles = makeStyles(() => ({
    root : {
        marginTop : 0,
        marginBottom : 0,
    }
}));

const NumberFormatCustom = (props) => {

    const { inputRef, prefix, value, ...other } = props;

    return (
      <NumberFormat
        {...other}
        value={value}
        getInputRef={inputRef}
        prefix={prefix}
        decimalSeparator="."
        displayType="input"
        thousandSeparator={true}
        allowNegative={true} 
        thousandsGroupStyle="thousand"
      />
    );
};

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.any.isRequired,
    prefix : PropTypes.string,
    value : PropTypes.any,
};

function NumberField(props) {
    const { form, name, label, disabled, size = 'small', variant = 'outlined', prefix='', onChange = null} = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];
    const classes = useStyles();

    const handleChange = (value) => {
        if (!onChange)  return;
        onChange(value);
    };

    return (
        <Controller
            as
            name={name}
            control={form.control}
            render={({ field: { onChange, onBlur, name, value } }) => {
                return (
                <TextField
                    margin="normal"
                    variant={variant}
                    fullWidth
                    label={label}
                    disabled={disabled}
                    error={!!hasError}
                    helperText={errors[name]?.message}
                    name={name}
                    value={value}
                    onChange={e => {
                        let newValue = +(e.target.value.substr(prefix.length).split(',').join(''));
                        handleChange(newValue);
                        onChange(newValue);
                    }}
                    onBlur={onBlur}
                    size={size}
                    InputProps={{
                        inputComponent : NumberFormatCustom,
                        inputProps : { prefix },
                    }}
                    className={classes.root}
                />
            );
        }}
        />
    );
}

export default NumberField;