import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { hidebadgeGGRecaptcha, isAuthentificated } from '../../../util';
import authApi from '../../../api/authApi';
import STORAGES_KEYS from '../../../constants/storages-keys';
import LoginForm from '../components/LoginForm';

LoginPage.propTypes = {
    location : PropTypes.any,
};

function LoginPage(props) {

    const history = useHistory();
    const isLogged = isAuthentificated();
    const [ isSubmitting, setSubmitting ] = useState(false);

    if (isLogged){
        history.push('/');
    }

    const { enqueueSnackbar } = useSnackbar();
    
    const handleSubmit = async (values, token) => {
        setSubmitting(true);
        try {
            const data = await authApi.signIn({ ...values, token });

            localStorage.setItem(STORAGES_KEYS.TOKEN, data.access_token);
            localStorage.setItem(STORAGES_KEYS.USER, JSON.stringify(data.user));
            
            hidebadgeGGRecaptcha();

            const path = props.location?.state?.from?.pathname;
            path ? history.push(path) : history.push('/');
        } catch (error) {
            enqueueSnackbar('L\'identifiant ou le mot de passe n\'est pas correcte ou votre compte est désactivé. Veuillez demander l\'administrateur pour le vérifier', { variant: 'error' });
            console.log('fail to login', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <LoginForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
    );
}

export default LoginPage;