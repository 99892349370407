import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import paramApi from '../api/paramApi';

export default function(){
  const [ agences, setAgences] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        const res = await paramApi.agence() || [];
        setAgences(res.map(item => ({...item, value : item.id })));
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ne peut pas télécharger la liste d\'agence', { variant: 'error' });
      }
    })();
  }, []);

  return agences;
}