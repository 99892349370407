import { Grid, makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

UserFilter.propTypes = {
    onChange : PropTypes.func,
};

const FILTERS_FIELD = [
    { name : 'lastName', label : 'Nom' },
    { name : 'firstName', label : 'Prénom' },
    { name : 'identifiant', label : 'Identifiant' },
    { name : 'email', label : 'Email' },
];

const useStyles = makeStyles((theme) => ({
    items : {
        padding : theme.spacing(2),
    }
}));

function UserFilter(props) {

    const { onChange } = props;
    const classes = useStyles();

    const handleChange = (e) => {
        if (!onChange)   return;
        onChange(e);
    };

    return (
        <Grid container spacing={2} className={classes.items}>
            {
                FILTERS_FIELD.map((item, index) => (
                    <Grid key={index} item container spacing={2} md={12} lg={6}>
                        <TextField
                            id={`input-${item.name}`}
                            label={item.label}
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            name={item.name}
                            onChange={handleChange}
                            size="small"
                        />
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default UserFilter;