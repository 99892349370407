import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import paramApi from '../api/paramApi';
import { LST_CATEGORY, LST_DIFFERENTS_ETATS, LST_ETAT_CUISINE, LST_ETAT_GENERAL, LST_HEATING_ENERGY, LST_HEATING_TYPE, LST_NATURE_MUR, LST_NATURE_SOUS_SOL, LST_NATURE_TOITURE, LST_STATUS_DPE, LST_TYPE_BIEN, LST_TYPE_CUISINE, LST_TYPE_MANDAT } from '../constants';

export default function(){
  const [ loadParams, setLoadParams] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        const apiParams = [
            paramApi.typeBiens(),
            paramApi.paramOption(),
        ];

        const [ typeBiens, paramOptions ] = await Promise.all(apiParams);

        localStorage.setItem(LST_TYPE_BIEN, JSON.stringify(typeBiens));
        localStorage.setItem(LST_HEATING_ENERGY, JSON.stringify(paramOptions.HEATING_ENERGY));
        localStorage.setItem(LST_HEATING_TYPE, JSON.stringify(paramOptions.HEATING_TYPE));
        localStorage.setItem(LST_STATUS_DPE, JSON.stringify(paramOptions.STATUS_DPE));
        localStorage.setItem(LST_CATEGORY, JSON.stringify(paramOptions.CATEGORY));
        localStorage.setItem(LST_TYPE_CUISINE, JSON.stringify(paramOptions.TYPE_CUISINE));
        localStorage.setItem(LST_ETAT_CUISINE, JSON.stringify(paramOptions.ETAT_CUISINE));
        localStorage.setItem(LST_ETAT_GENERAL, JSON.stringify(paramOptions.ETAT_GENERAL));
        localStorage.setItem(LST_DIFFERENTS_ETATS, JSON.stringify(paramOptions.DIFFERENTS_ETATS));
        localStorage.setItem(LST_NATURE_TOITURE, JSON.stringify(paramOptions.NATURE_TOITURE));
        localStorage.setItem(LST_NATURE_SOUS_SOL, JSON.stringify(paramOptions.NATURE_SOUS_SOL));
        localStorage.setItem(LST_NATURE_MUR, JSON.stringify(paramOptions.NATURE_MUR));
        localStorage.setItem(LST_TYPE_MANDAT, JSON.stringify(paramOptions.TYPE_MANDAT));

        setLoadParams(true);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ne peut pas télécharger les paramètres', { variant: 'error' });
      }
    })();
  }, []);

  return loadParams;
}