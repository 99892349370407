import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import paramApi from '../api/paramApi';
import { loadScriptByURL } from '../util';

export default function(){
  const [ captcha, setCaptcha ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        const value = await paramApi.keyCaptcha();
        loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${value}`,  () => console.log('Script loaded!'));
        setCaptcha(value);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ne peut pas télécharger les types de bien', { variant: 'error' });
      }
    })();
  }, []);

  return captcha;
}