import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { InputType } from './inputType';

InputField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    size : PropTypes.string,
    variant : PropTypes.string,

    type: PropTypes.string,

    onChange: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : 0,
        marginBottom : 0,
    },
    input : {
        color : theme.palette.paragraph.main,
    }
}));

function InputField(props) {
    const { form, name, label, disabled, size = 'small', variant = 'outlined', onChange = null, id = null, type = null} = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];

    const classes = useStyles();

    const handleChange = (e) => {
        if (!onChange)  return;
        onChange(e);
    };

    /**
     * Format value by type
     * @param {string} value 
     * @returns {string}
     */
    const formatValue = (value) => {
        if (type === InputType.PRICE) {
            // Check if it is a numeric character
            if (value && !Number.isNaN(Number(`${value}`.replace(/\s/g, '')))) {
                // Format value to a currency format and remove the currency symbol at the end
                let result = new Intl.NumberFormat().format(`${value}`.replace(/\s/g, '')).replace(/,\d+/, '');
                // Check if there is a decimal
                if (value.toString().indexOf('.') >= 0) {
                    // Add the decimal
                    result += `${value.toString().slice(value.toString().indexOf('.'))}`;
                } 
                return result;
            } else {
                return;
            }
        }
        return value;
    };

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                    id={id}
                    margin="normal"
                    variant={variant}
                    fullWidth
                    label={label}
                    disabled={disabled}
                    error={!!hasError}
                    helperText={errors[name]?.message}
                    name={name}
                    value={formatValue(value)}
                    onChange={(e) => {
                        if (type === InputType.PRICE) {
                            // If value is empty bypass
                            if (e.target.value === '') {
                                handleChange(e);
                                onChange(e);
                                return;
                            }
                            // Accept only digit or '.'
                            if (!/[\d.]$/.test(e.target.value)) {
                                e.preventDefault();
                                e.stopPropagation();
                                return;
                            }
                        }
                        handleChange(e);
                        onChange(e);
                    }}
                    onBlur={onBlur}
                    size={size}
                    defaultValue={value}
                    className={classes.root}
                    InputProps={{
                        className : classes.input,
                    }}
                />
            )}
        />
    );
}

export default InputField;