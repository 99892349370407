import { Box, Chip, FormControl, InputLabel, makeStyles, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';

MultipleSelect.propTypes = {
    onChange : PropTypes.func,
    name : PropTypes.string,
    label : PropTypes.string,
    list : PropTypes.array,
    listDefault : PropTypes.array,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(value, list = [], theme) {
    return {
      fontWeight:
      list.findIndex(item => item.value === value) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

const useStyles = makeStyles((theme) => ({
    selType : {
        marginLeft : theme.spacing(1),
    },
}));

function MultipleSelect(props) {

    const { name, label, list, onChange, listDefault = [] } = props;

    const classes = useStyles();
    const [ statusSels, setStatusSels ] = useState([ ...listDefault ]);
    const theme = useTheme();

    const handleChange = (e) => {
        const { target: { value } } = e;
        setStatusSels(typeof value === 'string' ? value.split(',') : value);

        onChange && onChange({ target : { name, value : value }});
    };

    return (
        <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel id={`sel-multiple-label-${name}`}>{ label }</InputLabel>
            <Select
                className={classes.selType}
                labelId={`sel-multiple-label-${name}`}
                id={`sel-multiple-${name}`}
                multiple
                value={statusSels}
                onChange={handleChange}
                input={<OutlinedInput id={`select-multiple-chip-${name}`} label={label} />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((item) => (
                            <Chip key={item.value} label={item.label} size="small" />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
                fullWidth
                variant="outlined"
                displayEmpty
                name={name}
            >
                {list.map((item, index) => (
                    <MenuItem key={index} value={item} style={getStyles(item.value, statusSels, theme)} >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MultipleSelect;