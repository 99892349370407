import axios from 'axios';

const otherApi = {
    async getAdresse(searchkey){
        const url = `https://api-adresse.data.gouv.fr/search/?limit=15&q=${searchkey}`;
        return axios.get(url);
    },
    async getConfig(){
        const url = '/conf.json';
        return axios.get(url);
    },
};

export default otherApi;