import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import RadioGroupField from '../../../../components/form-controls/RadioGroupField';
import SelectField from '../../../../components/form-controls/SelectField';
import { getCategory, getDifferenceEtat, getNatureMur, getNatureSousol, getNatureToiture, getTypeCuisine } from '../../../../util';
import { is_appartement_maison, is_appartement_maison_immeuble, is_not_terrain_stationnement } from '../../../../util/typeBienService';
import InputField from './../../../../components/form-controls/InputField';

AmenagementSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
    bienType : PropTypes.number,
};

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function AmenagementSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled, bienType } = props;

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Aménagement</Typography>
                <Grid container spacing={2}>
                    { is_appartement_maison_immeuble(bienType) && 
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de chambres</InputLabel>
                            <InputField disabled={isDisabled} name="bedroomCount" form={form} />
                        </Grid>
                    }
                    { is_appartement_maison(bienType) && 
                        <Grid item xs={12}>
                             <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Orientation</InputLabel>
                            <InputField disabled={isDisabled} name="orientation" form={form} />
                        </Grid>
                    }
                    { is_not_terrain_stationnement(bienType) && <>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Etat des murs</InputLabel>
                            <SelectField disabled={isDisabled} name="wallsSt" form={form} items={Object.values(getDifferenceEtat())} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Etat de la toiture</InputLabel>
                            <SelectField disabled={isDisabled} name="roofSt" form={form} items={Object.values(getDifferenceEtat())} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Type des murs</InputLabel>
                            <SelectField disabled={isDisabled} name="wallsNt" form={form} items={Object.values(getNatureMur())} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Type de la toiture</InputLabel>
                            <SelectField disabled={isDisabled} name="roofNt" form={form} items={Object.values(getNatureToiture())} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Type de sous sol</InputLabel>
                            <SelectField disabled={isDisabled} name="baseNt" form={form} items={Object.values(getNatureSousol())} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Catégorie</InputLabel>
                            <SelectField disabled={isDisabled} name="cat" form={form} items={Object.values(getCategory())} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Type de cuisine</InputLabel>
                            <SelectField disabled={isDisabled} name="ktType" form={form} items={Object.values(getTypeCuisine())} />
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroupField disabled={isDisabled} name="attic" label="Grenier" form={form} items={ITEMS_CHOICE} />
                        </Grid>
                    </>}
                </Grid>
            </Paper>
        </Box>
    );
}

export default AmenagementSection;