export const LST_TYPE_BIEN = 'LST_TYPE_BIEN';
export const LST_HEATING_ENERGY = 'LST_HEATING_ENERGY';
export const LST_HEATING_TYPE = 'LST_HEATING_TYPE';
export const LST_STATUS_DPE = 'LST_STATUS_DPE';
export const LST_CATEGORY = 'LST_CATEGORY';
export const LST_TYPE_CUISINE = 'LST_TYPE_CUISINE';
export const LST_ETAT_CUISINE = 'LST_ETAT_CUISINE';
export const LST_ETAT_GENERAL = 'LST_ETAT_GENERAL';
export const LST_DIFFERENTS_ETATS = 'LST_DIFFERENTS_ETATS';
export const LST_NATURE_TOITURE = 'LST_NATURE_TOITURE';
export const LST_NATURE_SOUS_SOL = 'LST_NATURE_SOUS_SOL';
export const LST_NATURE_MUR = 'LST_NATURE_MUR';
export const LST_TYPE_MANDAT = 'LST_TYPE_MANDAT';

export const PHOTO_TYPE = {
    COUVERTURE : 'COUVERTURE',
    NORMAL : 'NORMAL',
};

