import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import InputField from './../../../../components/form-controls/InputField';
import RadioGroupField from './../../../../components/form-controls/RadioGroupField';
import { is_maison_immeuble, is_not_terrain, is_not_terrain_stationnement } from '../../../../util';

ImmeubleSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
    bienType : PropTypes.number,
};

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

const LIST_CHOICE = [
    { name : 'lift', label : 'Ascenseur' },
    { name : 'digicode', label : 'Digicode' },
    { name : 'interphone', label : 'Interphone' },
    { name : 'rdc', label : 'RDC' },
    { name : 'cleaning', label : 'Société nettoyage' },
    { name : 'handicapAccess', label : 'Accès handicapé' },
    { name : 'cable', label : 'Cablé' },
    { name : 'videoPhone', label : 'Videophone' },
];

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function ImmeubleSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled, bienType } = props;

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Immeuble</Typography>
                <Grid container spacing={2}>
                    { is_not_terrain(bienType) && 
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Année de construction</InputLabel>
                            <InputField disabled={isDisabled} name="constructionYear" form={form} InputLabelProps={{
                                shrink: true,
                            }}/>
                        </Grid>
                    }
                    { is_not_terrain_stationnement(bienType) && <>
                        <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre d&#39;étages</InputLabel>
                            <InputField disabled={isDisabled} name="floorCount" form={form} />
                        </Grid>
                      
                        {
                            LIST_CHOICE.map((it, index) =>(
                                <Grid key={index} item xs={12}>
                                    <RadioGroupField disabled={isDisabled} name={it.name} label={it.label} form={form} items={ITEMS_CHOICE} />
                                </Grid>
                            ))
                        }
                    </>}
                    { is_maison_immeuble(bienType) && 
                        <Grid item xs={12}>
                            <RadioGroupField disabled={isDisabled} name="gardian" label="Gardien" form={form} items={ITEMS_CHOICE} />
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Box>
    );
}

export default ImmeubleSection;