import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { isAuthentificated } from '../../util';

PrivateRouteItem.propTypes = {
    component : PropTypes.any,
};

function PrivateRouteItem(props) {

    const { component : Component, ...rest } = props;
    const location = useLocation();
    const isLoggedIn = isAuthentificated();
    
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn 
                ? ( <Component {...props} />) 
                : ( <Redirect to={{ pathname: '/auth/login', state: { from: location } }} />)
            }
        />
    );
}

export default PrivateRouteItem;