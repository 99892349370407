import { Box, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import InputField from '../../../../components/form-controls/InputField';
import SelectField from '../../../../components/form-controls/SelectField';
import { ROLES_CODES } from '../../../../constants';
import useAgence from '../../../../hook/useAgence';
import useRoles from '../../../../hook/useRoles';
import { isSuperAdmin } from '../../../../util';

UserAddForm.propTypes = {
    form : PropTypes.any,
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
        width : '100%',
    },
}));

function UserAddForm(props) {

    const classes = useStyles();
    const roles = useRoles();
    const agences = useAgence() || [];
    const { form } = props;

    const isSuperAdm = isSuperAdmin();

    return (
        <Box className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputField name="identifiant" label="Identifiant *" form={form} variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <InputField name="email" label="Email *" form={form} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputField name="lastName" label="Nom" form={form} variant="outlined" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputField name="firstName" label="Prénom" form={form} variant="outlined" />
                </Grid>
                {
                    isSuperAdm && <>
                        <Grid item xs={12}>
                            <SelectField name="roleCode" label="Rôle" form={form} items={roles.filter(role => role.code !== ROLES_CODES.SUPER_ADM)} />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectField name="agenceId" label="Agence" form={form} items={agences} />
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    );
}

export default UserAddForm;