import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import NumberField from '../../../../components/form-controls/NumberField';
import RadioGroupField from './../../../../components/form-controls/RadioGroupField';

CoproprieteSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
};

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function CoproprieteSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled } = props;

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Copropriété</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de lots</InputLabel>
                        <NumberField disabled={isDisabled} name="nombreLot" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de lots d’habitation</InputLabel>
                        <NumberField disabled={isDisabled} name="nombreLotHabitation" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupField disabled={isDisabled} name="procedure" label="Procédure en cours" form={form} items={ITEMS_CHOICE} />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default CoproprieteSection;