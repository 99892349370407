import { Drawer, Hidden, makeStyles, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import DrawerItem from './DrawerItem';

Sidebar.propTypes = {
  onToggle : PropTypes.func,
  open : PropTypes.bool,
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')] : {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper : {
    width: drawerWidth,
  },
}));

function Sidebar(props) {

  const classes = useStyles();
  const theme = useTheme();

  const { open, onToggle } = props;

  const handleClose = () => {
    if (!onToggle)  return;
    onToggle();
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={handleClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerItem isToggle={true} onClose={handleClose} />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerItem isToggle={false} />
        </Drawer>
      </Hidden>
      </nav>
  );
}

export default Sidebar;