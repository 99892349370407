import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, LinearProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import bienApi from './../../../../api/bienApi';
import PhotoForm from './PhotoForm';
import PhotoReview from './PhotoReview';
import { useSnackbar } from 'notistack';
import { setData } from '../../bienSlice';

PhotoAdd.propTypes = {
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(4),
        fontWeight : 'bold',
        fontSize : theme.typography.h6.fontSize,
    },
    form : {
        margin : theme.spacing(1),
    },
    paper : {
        padding : theme.spacing(2),
    },
    boxButton : {
        display : 'flex',
        justifyContent : 'flex-end',
    },
    button : {
        textTransform : 'none',
    },
    progress : {
        position : 'absolute',
        top : theme.spacing(8),
        left : 0,
        right: 0,
    },
    title : {
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    }
}));

function PhotoAdd() {

    const classes = useStyles();
    const [ files, setFiles ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const bienInfo = useSelector((state) => state.bien);
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    console.log(loading);

    const handleChangeFile = ({ target }) => {
        const file = {
            name: target.files[0].name,
            file: target.files[0],
          };
      
          setFiles([...files, file]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = files;
        newFiles.splice(index, 1);
    
        setFiles([...newFiles]);
    };

    const form = useForm({
        defaultValues: {},
        resolver : yupResolver(yup.object().shape({})),
    });

    const savePhoto = async () => {
        try {
            setLoading(true);
            const promises = files.map(file => {
                const formData = new FormData();
                formData.append('file', file.file);
                return bienApi.uploadPhoto(bienInfo.uuid, 'NORMAL', formData);
            });
    
            await Promise.all(promises);

            setFiles([]);
            enqueueSnackbar('Chargement de la photo avec succès', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Une erreur est survenue dans le chargement de la photo', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const getBien = async () => {
        try {
            setLoading(true);
            const bien = await bienApi.get(bienInfo.uuid);
            const action = setData(bien);
            dispatch(action);
        } catch (error) {
            enqueueSnackbar('Une erreur est survenue dans le téléchargement des photos', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        await savePhoto();
        await getBien();
    };

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                { loading && <LinearProgress className={classes.progress} /> }
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" className={classes.title}>Chargement des photos</Typography>
                    <Box display="block">
                        <form className={classes.form}  >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <PhotoForm form={form} onChange={handleChangeFile} />
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
                <Grid container spacing={1}>
                    <Grid xs={12} item>
                        <PhotoReview files={files} onRemove={handleRemoveFile} />
                    </Grid>
                    <Grid xs={12} item>
                        <Paper elevation={0}>
                            { !!files.length && 
                                <Grid item xs={12} className={classes.boxButton}>
                                    <Button variant="contained" color="primary" type="button" onClick={handleSubmit} className={classes.button}>
                                        Enregistrer
                                    </Button>
                                </Grid>
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default PhotoAdd;