import { AppBar, Box, Hidden, makeStyles, Toolbar, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import DateRangeIcon from '@material-ui/icons/DateRange';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import authApi from '../../api/authApi';
import { getUserInfo, logout } from '../../util';
import { useHistory } from 'react-router-dom';

Header.propTypes = {
    onToggle : PropTypes.func,
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        flexGrow : 1,
        backgroundColor : '#63AA9C',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
    },
    toolbar : {
        justifyContent : 'flex-end',
    }
}));

function Header(props) {

    const classes = useStyles();
    const history = useHistory();

    const { onToggle } = props;

    const handleLogout = async () => {
        try {
            await authApi.logout();
        } catch (error) {
            console.log('Failed logout', error.message);
        }

        logout();
    };

    const user = getUserInfo();

    const handleClick = () => {
        if (!onToggle)   return;
        onToggle();
    };

    const handleClickProfile = () => {
        history.push('/profil');
    };

    return (
        <AppBar position="fixed" className={classes.appBar} nowrap="true">
            <Toolbar className={classes.toolbar}>
                <Box>
                    <Hidden xsDown>
                        {
                            user && 
                            <Tooltip title="Profil">
                                <IconButton color="inherit" size="medium" aria-label="Profil" onClick={handleClickProfile}>
                                    <AccountCircleIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Déconnexion">
                            <IconButton color="inherit" onClick={handleLogout} size="medium" aria-label="Déconnexion">
                                <ExitToAppIcon />
                            </IconButton>
                        </Tooltip>
                        
                    </Hidden>
                </Box>
                
                <Tooltip title="Ouvrir menu">
                    <IconButton color="inherit" aria-label="Ouvrir menu" edge="start" onClick={handleClick} className={classes.menuButton} >
                        <MenuIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
}

export default Header;