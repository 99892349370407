import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getHeatingEnergys, getHeatingTypes } from '../../../../util/localstorageService';
import RadioGroupField from './../../../../components/form-controls/RadioGroupField';
import SelectField from './../../../../components/form-controls/SelectField';

ChauffageClimatisationSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
};

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function ChauffageClimatisationSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled } = props;

    const ITEMS_HEATING_TYPE = getHeatingTypes();
    const ITEMS_HEATING_ENERGY = getHeatingEnergys();

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Chauffage et climatisation</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Type de chauffage</InputLabel>
                        <SelectField disabled={isDisabled} name="htType" form={form} items={ITEMS_HEATING_TYPE} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nature de chauffage</InputLabel>
                        <SelectField disabled={isDisabled} name="htNature" form={form} items={ITEMS_HEATING_ENERGY} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupField disabled={isDisabled} name="fireplace" label="Cheminée" form={form} items={ITEMS_CHOICE} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupField disabled={isDisabled} name="airConditioning" label="Climatisation" form={form} items={ITEMS_CHOICE} />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default ChauffageClimatisationSection;