import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

SelectField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    items : PropTypes.array.isRequired,
    onChange : PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : 0,
        marginBottom : 0,
    },
    option : {
        color : theme.palette.paragraph.main,
    }
}));

function SelectField(props) {
    const { form, name, label, disabled, items, onChange } = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];

    const classes = useStyles();

    const handleChange = (e) => {
        if (!onChange)   return;
        onChange(e);
    };

    return (
        <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, onBlur, value, name } }) => (
            <FormControl variant="outlined" fullWidth margin="dense" className={classes.root}>
                <InputLabel id={`sel-label-${name}`}>{label}</InputLabel>
                <Select
                    labelId={`sel-label-${name}`}
                    id={`sel-id-${name}`}
                    value={value}
                    name={name}
                    onChange={(e)=> {
                        onChange(e);
                        handleChange(e);
                    }}
                    label={label}
                    error={!!hasError}
                    disabled={disabled}
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                    defaultValue=""
                >
                    {
                        items.map((item, index) => (
                            <MenuItem key={index} value={item.value} className={classes.option}>{item.label}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )}
        />
    );
}

export default SelectField;