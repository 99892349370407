import { Box, Button, Container, CssBaseline, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import InputField from '../../../../components/form-controls/InputField';
import useCaptcha from '../../../../hook/useCaptcha';
import logo from './../../../../assets/images/Foncia_logoh_66_66.png';

const useStyles = makeStyles(theme => ({
    root : {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit : {
        margin : theme.spacing(3, 0, 2, 0),
    },
    progress : {
        position : 'absolute',
        top : theme.spacing(1),
        left : 0,
        right: 0,
    },
    paper : {
        marginTop : theme.spacing(6),
        padding : theme.spacing(3),
    },
    form : {
        marginTop : theme.spacing(6)
    }
}));

ForgetPwdForm.propTypes = {
    onSubmit: PropTypes.func,
    form: PropTypes.any,
    isSubmitting: PropTypes.bool,
};

function ForgetPwdForm(props) {

    const { onSubmit, form, isSubmitting } = props;
    const classes = useStyles();
    const captcha = useCaptcha();

    const handleSubmit = (values) => {
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(captcha, { action: 'submit' });
                onSubmit && onSubmit(values, token);
            } catch (error) {
                console.log(error);
            }
        });
    };

    return (<>
        {
            captcha && 
            <Container component="main" maxWidth="xs">
                <Paper elevation={1} className={classes.paper}>
                    <CssBaseline />
                    <div className={classes.root}>
                        { isSubmitting && <LinearProgress className={classes.progress} /> }
                        <Box><img src={logo} alt="Les franchisés" /></Box>
                        <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.form}>
                            <InputField name="email" label="Votre email" form={form} />

                            <Button disabled={isSubmitting} type="submit" className={classes.submit} variant='contained' color='primary' fullWidth size="medium">
                                Initialiser mot de passe
                            </Button>
                        </form>
                    </div>
                </Paper>
            </Container>
        }
    </>);
}

export default ForgetPwdForm;