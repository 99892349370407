import { Card, CardContent, CardHeader, Grid, IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';

const useStyles = makeStyles(() => ({
  header: {
    paddingBottom: 0,
    position: 'absolute',
    width: '100%',
  },
  button: {
    right: 16,
    backgroundColor : '#dae2e2',
  },
  image : {
      objectFit : 'contain',
      maxHeight : '100%',
      maxWidth : '100%',
  },
  imageContain : {
    maxHeight : '300px',
  },
}));

const FileItem = ({ file, onRemove }) => {
  const classes = useStyles();

  const handleRemove = (event) => {
    event.preventDefault();
    if (!onRemove)  return;
    onRemove();
  };

  const StyledCard = withStyles({
    root: {
      textAlign: 'center',
      border: '1px dashed #B3BFD2',
    },
  })(Card);

  const StyledCardContent = withStyles({
    root: {
      padding: '0 !important',
    },
  })(CardContent);

  return (
    <Grid item xs={6} md={4} lg={4} className="FileItem">
      <label>
        <StyledCard variant="outlined" classes={{ root: 'valid' }} >
          <CardHeader className={classes.header}
            action={
              <IconButton onClick={handleRemove} className={classes.button}>
                <Delete color="secondary" className={classes.icon} />
              </IconButton>
            }
          />
          <StyledCardContent>
            <Grid alignItems="center" justify="center" container className={classes.imageContain} >
                <img src={file.file} alt={file.name} className={classes.image} />
            </Grid>
          </StyledCardContent>
        </StyledCard>
      </label>
    </Grid>
  );
};

FileItem.propTypes = {
    file: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
};

export default FileItem;
