import { alpha, Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import bienApi from '../../../api/bienApi';
import Popup from '../../../components/Popup';
import BienItem from './BienItem';

BienList.propTypes = {
    data : PropTypes.array,
    callback : PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    center : {
        textAlign : 'center',
    },
    tollbar : {
        display : 'flex',
        justifyContent : 'space-between',
        paddingLeft : theme.spacing(2),
        paddingRight : theme.spacing(1),
        backgroundColor : alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
    },
    button : {
        textTransform : 'none',
    },
    bold : {
        fontWeight : 'bold',
    }
}));

function BienList({ data = [], callback }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);
    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});
    const [ lstSels, setLstSels ] = useState([]);

    console.log(loading);

    const handleDiffuserBien = (bien) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Diffuser ce bien',
            content : <>Etes-vous sûr de vouloir diffuser ce bien <b>{bien.reference}</b> ?</>,
            buttons : [
                {label : 'Diffuser', color : 'primary', onClick : () => diffuserBien(bien)},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const diffuserBien = async (bien) => {
        setLoading(true);
        try {
            await bienApi.diffuse(bien.uuid);
            enqueueSnackbar('Diffuser avec succès', { variant: 'success' });
            if (callback)    callback();
        } catch (error) {
            enqueueSnackbar('Ne pas pouvoir de diffuser ce bien en ce moment, veuillez réessayer ultérieurement', {variant: 'error'});
            console.log('fail to diffuser bien', error);
        } finally {
            resetState();
        }
    };

    const handleUndiffuserBien = (bien) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Arrêter la diffusion du bien',
            content : <>{'Etes-vous sûr de vouloir arrêter la diffusion de ce bien ?'} <b>{bien.reference}</b></>,
            buttons : [
                {label : 'Arrêter', color : 'primary', onClick : () => undiffuserBien(bien)},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const undiffuserBien = async (bien) => {
        setLoading(true);
        try {
            await bienApi.undiffuser(bien.uuid);
            enqueueSnackbar('La diffusion a été arrêté avec succès', { variant: 'success' });
            if (callback)    callback();
        } catch (error) {
            enqueueSnackbar('Une erreur est survenu lors de l\'arrêt de la diffusion, veuillez réessayer ultérieurement', { variant: 'error' });
            console.log('fail to undiffuser bien', error);
        } finally {
            resetState();
        }
    };

    const handleArchiverBien = (bien) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Archiver ce bien',
            content : <>{'Etes-vous sûr de vouloir archiver ce bien ?'} <b>{bien.reference}</b></>,
            buttons : [
                {label : 'Archiver', color : 'primary', onClick : () => archiverBien(bien)},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const archiverBien = async (bien) => {
        setLoading(true);
        try {
            await bienApi.archiver(bien.uuid);
            enqueueSnackbar('Archiver le bien avec succès', { variant: 'success' });
            if (callback)    callback();
        } catch (error) {
            enqueueSnackbar('Ne pas pouvoir d\'archiver de diffusion ce bien en ce moment, veuillez réessayer ultérieurement', { variant: 'error' });
            console.log('fail to archiver bien', error);
        } finally {
            resetState();
        }
    };

    const handleRemoveBien = async (bien) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Supprimer ce bien',
            content : <>Etes-vous sûr de vouloir supprimer ce bien <b>{bien.reference}</b> ?</>,
            buttons : [
                {label : 'Supprimer', color : 'primary', onClick : () => removeBien(bien)},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const removeBien = async (bien) => {
        setLoading(true);
        try {
            await bienApi.delete(bien.uuid);
            enqueueSnackbar('Supprimer avec succès', { variant: 'success' });
            if (callback)    callback();
        } catch (error) {
            enqueueSnackbar('Ne pas pouvoir de supprimer ce bien en ce moment, veuillez réessayer ultérieurement', {variant: 'error'});
            console.log('fail to delete bien', error);
        } finally {
            resetState();
        }
    };

    const handleSelect = (isAdd, uuid) => {
        if (isAdd){
            !lstSels.includes(uuid) && setLstSels([...lstSels, uuid]);
        } else {
            const index = lstSels.indexOf(uuid);
            const newSels = [...lstSels];
            index > -1 && newSels.splice(index, 1);
            setLstSels(newSels);
        }
    };

    const diffuserAllBien = async () => {
        setLoading(true);
        try {
            const promises = lstSels.map(uuid => bienApi.diffuse(uuid));
            const values = await Promise.allSettled(promises);
            enqueueSnackbar(getMessageDiffuserAll(values), { variant: 'success' });
            if (callback)    callback();
        } catch (error) {
            enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
            console.log('fail to diffuser list bien', error);
        } finally {
            resetState();
        }
    };

    const getMessageDiffuserAll = (values) => {
        let msg = '';
        // eslint-disable-next-line no-nested-ternary
        const { succes, error } = values.reduce((objCount, item) => item.status === 'fulfilled' ? { ...objCount, succes : objCount.succes + 1} : item.status === 'rejected' ? { ...objCount, error : objCount.error + 1} : objCount, { succes : 0, error : 0});
        if (succes > 0) msg += `Diffusion des ${succes} biens avec succès `;
        if (error > 0) msg += `Erreur lors de la diffusion des ${error} biens`;

        return msg;
    };

    const handleDiffuserAll = () => {
        if (!lstSels.length)    return;
        setOpenPopup(true);
        setParamPopup({
            title : 'Diffuser les biens selectionnés',
            content : <>Etes-vous sûr de vouloir diffuser ces biens ? </>,
            buttons : [
                {label : 'Diffuser', color : 'primary', onClick : () => diffuserAllBien()},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const resetState = () => {
        setLoading(false);
        setOpenPopup(false);
        setParamPopup({});
    };

    return (
        <>
            {
                lstSels.length > 0 && 
                <Toolbar className={classes.tollbar}>
                    <Typography>{`${lstSels.length} selectionné${lstSels.length > 1 ? 's' : ''}`}</Typography>
                    <Button variant="contained" size="small" color="primary" onClick={handleDiffuserAll} className={classes.button}>Tous diffuser</Button>
                </Toolbar>
            }
            
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell className={classes.bold}>Réference</TableCell>
                            <TableCell className={classes.bold}>Type de Bien</TableCell>
                            <TableCell className={classes.bold}>Adresse</TableCell>
                            <TableCell className={classes.bold}>{'Prix d\'estimation'}</TableCell>
                            <TableCell className={classes.bold}>Photo</TableCell>
                            <TableCell className={classes.bold} align="center">Statut</TableCell>
                            <TableCell align="center" className={classes.bold}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Boolean(data.length) && data.map((bien, index) => (
                                <BienItem key={index} bien={bien} onDiffuse={handleDiffuserBien} onRemove={handleRemoveBien} onSelect={handleSelect} onUndiffuser={handleUndiffuserBien} onArchiver={handleArchiverBien} />
                            ))
                        }
                        {
                            Boolean(!data.length) && (
                                <TableRow hover >
                                    <TableCell colSpan={8} className={classes.center}>Aucune bien trouvé</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Popup open={openPopup} {...paramPopup} />
        </>
    );
}

export default BienList;