import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';

CheckboxField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    isChecked : PropTypes.bool,
    onChangeExterne : PropTypes.func,
};

function CheckboxField(props) {

    const { form, name, label, disabled, onChangeExterne } = props;
    const [ checked, setChecked ] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={(e) => {
                                if (onChangeExterne)    onChangeExterne(e);
                                handleChange(e);
                                onChange(e);
                            }}
                            name={name}
                            color="primary"
                            value={value}
                            disabled={disabled}
                            onBlur={onBlur}
                        />
                    }
                    label={label}
                />
            )}
        />
    );
}

export default CheckboxField;