import React from 'react';
// import logo from './../../assets/images/Foncia_logoh_66_66.png';
import logo from './../../assets/images/SaisiR-mini.png';
import { Box, makeStyles, Paper } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

Logo.propTypes = {
};

const useStyles = makeStyles((theme) => ({
    root : {
        zIndex: 2000,

        position: 'fixed',
        top: 0,
        left: 0,
        right: 'auto',

        marginLeft: theme.spacing(2),
        backgroundColor: 'white',
    },
    paper : {
        padding: theme.spacing(1),
    },
    image : {
        // width : '80%',
    },
}));

function Logo() {

    const classes = useStyles();

    return (
        <RouterLink to="/">
            <Box className={classes.root}>
                <Paper elevation={1} className={classes.paper}>
                    <img src={logo} alt="Les franchisés" className={classes.image} height={50} />
                </Paper>
            </Box>
        </RouterLink>
    );
}

export default Logo;