import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { is_immeuble, is_not_immeuble_terrain, is_not_terrain, is_not_terrain_stationnement } from '../../../../util';
import InputField from './../../../../components/form-controls/InputField';
import RadioGroupField from './../../../../components/form-controls/RadioGroupField';

StationnementSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
    bienType : PropTypes.number,
};

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function StationnementSection(props) {
    const classes = useStyles();
    const { form, hasValide : isDisabled, bienType } = props;

    
    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Stationnement</Typography>

                <Grid container spacing={2}>
                    { is_not_terrain(bienType) && 
                        <Grid item xs={12}>
                             <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de box</InputLabel>
                            <InputField disabled={isDisabled} name="boxCount" form={form} />
                        </Grid>
                    }
                    { is_immeuble(bienType) && <>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de parking intérieur</InputLabel>
                            <InputField disabled={isDisabled} name="internalParking" form={form} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de parking extérieur</InputLabel>
                            <InputField disabled={isDisabled} name="externalParking" form={form} />
                        </Grid>
                    </>}
                    { is_not_immeuble_terrain(bienType) && 
                        <Grid item xs={12}>
                             <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de parking</InputLabel>
                            <InputField disabled={isDisabled} name="parkingCount" form={form} />
                        </Grid>
                    }
                    { is_not_terrain_stationnement(bienType) &&
                        <Grid item xs={12}>
                            <RadioGroupField disabled={isDisabled} name="bikeRoom" label="Local à vélo" form={form} items={ITEMS_CHOICE} />
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Box>
    );
}

export default StationnementSection;