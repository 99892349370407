import { LST_CATEGORY, LST_DIFFERENTS_ETATS, LST_ETAT_CUISINE, LST_ETAT_GENERAL, LST_HEATING_ENERGY, LST_HEATING_TYPE, LST_NATURE_MUR, LST_NATURE_SOUS_SOL, LST_NATURE_TOITURE, LST_STATUS_DPE, LST_TYPE_BIEN, LST_TYPE_CUISINE, LST_TYPE_MANDAT } from '../constants';

const getTypeBiens = () => {
    return getFromLocalstorage(LST_TYPE_BIEN);
};

const getEtatGeneral = () => {
    return getFromLocalstorage(LST_ETAT_GENERAL);
};

const getCategory = () => {
    return getFromLocalstorage(LST_CATEGORY);
};

const getTypeCuisine = () => {
    return getFromLocalstorage(LST_TYPE_CUISINE);
};

const getEtatCuisine = () => {
    return getFromLocalstorage(LST_ETAT_CUISINE);
};

const getDifferenceEtat = () => {
    return getFromLocalstorage(LST_DIFFERENTS_ETATS);
};

const getNatureToiture = () => {
    return getFromLocalstorage(LST_NATURE_TOITURE);
};

const getNatureSousol = () => {
    return getFromLocalstorage(LST_NATURE_SOUS_SOL);
};

const getNatureMur = () => {
    return getFromLocalstorage(LST_NATURE_MUR);
};

const getHeatingEnergys = () => {
    return getFromLocalstorage(LST_HEATING_ENERGY);
};

const getHeatingTypes = () => {
    return getFromLocalstorage(LST_HEATING_TYPE);
};

const getStatusDpes = () => {
    return getFromLocalstorage(LST_STATUS_DPE);
};

const getFromLocalstorage = (key) => {
    const lst = JSON.parse(localStorage.getItem(key) || '[]');
    return lst.map(item => {
        item.value = item.id;
        return item;
    });
};

const getTypeMandat = () => {
    return getFromLocalstorage(LST_TYPE_MANDAT);
};

export {
    getTypeBiens, getEtatGeneral, getCategory, getTypeCuisine, getEtatCuisine, getDifferenceEtat, getNatureToiture,
    getNatureSousol, getNatureMur, getHeatingEnergys, getHeatingTypes, getStatusDpes, getTypeMandat
};
