import { TableCell, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PropTypes from 'prop-types';
import React from 'react';
import MenuMore from '../../../../components/MenuMore';
import { ROLES_CODES } from '../../../../constants';
import { getUserInfo, isAgenceAdmin, isSuperAdmin } from '../../../../util';

UserItem.propTypes = {
    user : PropTypes.object,
    onModify : PropTypes.func,
    onActiver : PropTypes.func,
    onDesactive : PropTypes.func,
};

function UserItem(props) {

    const { user, onModify, onActiver, onDesactive } = props;
    const isSuperAdm = isSuperAdmin();
    const isAgenceAdm = isAgenceAdmin();

    const handleAction = (actionFunc) => {
        if (!actionFunc)   return;
        actionFunc(user);
    };

    const me = getUserInfo();
    const isMe = me.username === user.username;
    const noAction = isAgenceAdm && user.roles[0].code === ROLES_CODES.AGENCE_ADM;

    const OPTIONS = [
        { title : 'Modifier', onClick : () => handleAction(onModify), icon : EditIcon, color: 'secondary', show : true },
        { title : 'Activer', onClick : () => handleAction(onActiver), icon : LockOpenIcon, color: 'primary', show : !user.active },
        { title : 'Désactiver', onClick : () => handleAction(onDesactive), icon : BlockIcon, color: 'error', show : user.active },
    ];

    return (
        <TableRow hover>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.username}</TableCell>
            {
                isSuperAdm && <TableCell>{user.agence?.label}</TableCell>
            }
            <TableCell>{user.roles[0]?.label}</TableCell>
            <TableCell>{user.active ? 'Activé' : 'Désactivé'}</TableCell>
            <TableCell align="center">
                {(!isMe && !noAction) && <>
                    <MenuMore options={OPTIONS} />
                </>}
            </TableCell>
        </TableRow>
    );
}

export default UserItem;