import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import userApi from '../../../api/userApi';
import CheckboxField from '../../../components/form-controls/CheckboxField';
import InputField from '../../../components/form-controls/InputField';
import PasswordField from '../../../components/form-controls/PasswordField';

UserProfileDetail.propTypes = {
    user : PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
    box : {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
    button : {
        textTransform : 'none',
    }
}));

function UserProfileDetail(props) {

    const classes = useStyles();
    const { user } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [ isSubmiting, setSubmiting ] = useState(false);
    const [ changePwd, setChangePwd ] = useState(false);

    const schema = yup.object().shape({
        firstName: yup.string().required('Veuillez saisir le prénom'),
        lastName: yup.string().required('Veuillez saisir le nom de famille'),
        isChangePwd : yup.boolean(),
        password : yup.string().when('isChangePwd', {
            is : true,
            then : yup.string().required('Veuillez entrer le mot de passe').min(6, 'Veuillez saisir au moins 6 caractères'),
        }),
        retypePassword : yup.string().when('isChangePwd', {
            is : true,
            then : yup.string().required('Veuillez retaper le mot de passe').oneOf([yup.ref('password')], 'Le mot de passe ne correspond pas'),
        }),
    });

    const form = useForm({
        defaultValues: {
            firstName: user.firstName, lastName: user.lastName, isChangePwd : false,
        },
        resolver : yupResolver(schema),
    });

    console.log('isSubmiting', isSubmiting);

    const handleSubmit = async (values) => {
        setSubmiting(true);
        try {
            delete values.retypePassword;
            await userApi.updateProfile(values);
            enqueueSnackbar('Modification avec succès', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Modification avec erreur, veuillez vérifier le saisis', { variant: 'error' });
            console.log(error);
        } finally {
            setSubmiting(false);
        }
    };

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} autoComplete="off">
        <Card>
            <CardHeader subheader="Les informations peuvent être modifiées" title="Profil" />
            <Divider />

            <CardContent>
                <Grid container spacing={3} >
                    <Grid item md={6} xs={12}>
                        <InputField name="firstName" label="Prénom" form={form} variant="outlined" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <InputField name="lastName" label="Nom" form={form} variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxField name="isChangePwd" label="Modifier le mot de passe" form={form} onChangeExterne={() => setChangePwd(!changePwd)} />
                    </Grid>
                </Grid>
            </CardContent>

            {
                changePwd && <>
                    <Divider />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">Modifier le mot de passe</Typography>
                        <Grid container spacing={3} >
                            <Grid item md={6} xs={12}>
                                <PasswordField name="password" label="Mot de passe" form={form} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <PasswordField name="retypePassword" label="Confirmer le mot de passe" form={form} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </>
            }
            
            <Divider />
            <Box className={classes.box}>
                <Button color="primary" variant="contained" type="submit" className={classes.button}> Enregistrer </Button>
            </Box>
        </Card>
    </form>
  );
}

export default UserProfileDetail;
