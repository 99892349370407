import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, LinearProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import bienApi from '../../../api/bienApi';
import { STATUS_BIEN_ARCHIVE } from '../../../constants';
import { afterDateDpe, getStatusDpes, getUserInfo, isAdmin, is_appartement_maison_localBureau, is_not_terrain_stationnement, parseDate } from '../../../util';
import { setData } from '../bienSlice';
import AmenagementSection from './AmenagementSection';
import ChauffageClimatisationSection from './ChauffageClimatisationSection';
import DpeSection from './DpeSection';
import EquipementSection from './EquipementSection';
import SurfaceDimensionSection from './SurfaceDimensionSection';

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(4),
        fontWeight : 'bold',
        fontSize : theme.typography.h6.fontSize,
    },
    paper : {
        padding : theme.spacing(2),
    },
    form : {
        margin : theme.spacing(3),
    },
    button : {
        textTransform : 'none',
    },
    boxHeader : {
        display : 'flex',
        justifyContent : 'space-between',
        padding : `0 ${theme.spacing(1)}`,
    },
    progress : {
        position : 'absolute',
        top : theme.spacing(8),
        left : 0,
        right: 0,
    },
    title : {
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    }
}));

function Caracteristique() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const bienInfo = useSelector((state) => state.bien);
    const { enqueueSnackbar } = useSnackbar();
    const [ isSubmiting, setSubmiting ] = useState(false);
    const isValid = bienInfo.status === STATUS_BIEN_ARCHIVE;
    const STATUS_DPE_OBTENU = getStatusDpes().find(item => item.code === 'OBTENU')?.id;

    const me = getUserInfo();
    const isMeOrAdmin = me.username === bienInfo.userCreateName || isAdmin();
    // eslint-disable-next-line no-irregular-whitespace
    const isDisabled = isValid ||!isMeOrAdmin;

    const SHAPE = {
        habitable : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
        terrain : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
        carrez : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
        sejour : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
        totalGroundSurface : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
        plancherSurface : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
        roofHeight : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    
        bedroomCount : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
        orientation : yup.string().nullable(),
    
        wallsSt : yup.number().nullable(),
        roofSt : yup.number().nullable(),
        wallsNt : yup.number().nullable(),
        baseNt : yup.number().nullable(),
        roofNt : yup.number().nullable(),
        cat : yup.number().nullable(),
        attic : yup.bool().nullable(),
        ktType : yup.number().nullable(),
    
        kitState : yup.number().nullable(),
        terrace : yup.bool().nullable(),
        balcony : yup.bool().nullable(),
        garden : yup.bool().nullable(),
        cellar : yup.bool().nullable(),
        porch : yup.bool().nullable(),
    
        htType : yup.number().nullable(),
        htNature : yup.number().nullable(),
        fireplace : yup.bool().nullable(),
        airConditioning : yup.bool().nullable(),
    
        dpeMixedPerimeses : yup.bool().nullable(),
        dpeAnneeRefPrixEnergie : yup.number().nullable().typeError('Veuillez saisir un numéro').when('dpeStat', {
            is : STATUS_DPE_OBTENU,
            then : yup.number().required('Veuillez saisir l\'année de référence d\'estimation').min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro')
        }),
        dpeMntEstimEnergie : yup.number().nullable().typeError('Veuillez saisir un numéro').when('dpeDate', (dpeDate) => {
            if (dpeDate && !afterDateDpe(dpeDate))  return yup.number().required('Veuillez saisir le montant d\'estimation d\'énergie').min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro');
            return yup.number().nullable().typeError('Veuillez saisir un numéro');
        }),
        dpeMntEstimEnergieMin : yup.number().nullable().typeError('Veuillez saisir un numéro').when('dpeDate', (dpeDate) => {
            if (afterDateDpe(dpeDate))  return yup.number().required('Veuillez saisir le montant d\'estimation d\'énergie minimal').min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro');
            return yup.number().nullable().typeError('Veuillez saisir un numéro');
        }),
        dpeMntEstimEnergieMax : yup.number().nullable().typeError('Veuillez saisir un numéro').when('dpeDate', (dpeDate) => {
            if (afterDateDpe(dpeDate))  return yup.number().required('Veuillez saisir le montant d\'estimation d\'énergie maximal').min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro');
            return yup.number().nullable().typeError('Veuillez saisir un numéro');
        }),
        dpeDate : yup.date().transform(parseDate).nullable().when('dpeStat', {
            is : STATUS_DPE_OBTENU,
            then : yup.date().transform(parseDate).required('Veuillez saisir la date de réalisation DPE')
        }),
        dpeGes : yup.number().nullable().typeError('Veuillez saisir un numéro').when('dpeStat', {
            is : STATUS_DPE_OBTENU,
            then : yup.number().required('Veuillez saisir la valeur GES').min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro')
        }),
        dpeKw : yup.number().nullable().typeError('Veuillez saisir un numéro').when('dpeStat', {
            is : STATUS_DPE_OBTENU,
            then : yup.number().required('Veuillez saisir la valeur KW').min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro')
        }),
        dpeStat : is_appartement_maison_localBureau(bienInfo.type) ? yup.number().required() : yup.number().nullable(),
    };
    const schema = yup.object().shape(SHAPE);
    const form = useForm({
        defaultValues: { ...bienInfo },
        resolver : yupResolver(schema),
    });

    const saveBien = async (values) => {
        setSubmiting(true);
        try {
            const params = verifyDataBeforeSend({ ...bienInfo, ...values });
            ['id', 'status', 'active', 'uuid', 'reference', 'userCreateName', 'dateEndDiffusion', 'photos'].forEach(item => delete params[item]);
            const bien = await bienApi.update(bienInfo.uuid, params);
            enqueueSnackbar('Mettre à jour le bien avec succès', { variant: 'success' });
            const action = setData(bien);
            dispatch(action);
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Une erreur est survenue dans la mise à jour un bien', { variant: 'error' });
        } finally {
            setSubmiting(false);
        }
    };

    const verifyDataBeforeSend = (params) => {
        if (params.dpeStat === STATUS_DPE_OBTENU){
            const { dpeAnneeRefPrixEnergie, dpeMntEstimEnergie, dpeDate, dpeGes, dpeKw, dpeMntEstimEnergieMin, dpeMntEstimEnergieMax } = params;
            let isNotRequired = [ dpeAnneeRefPrixEnergie, dpeDate, dpeGes, dpeKw ].some(item => !item);

            if (afterDateDpe(dpeDate) && (!dpeMntEstimEnergieMin || !dpeMntEstimEnergieMax))    isNotRequired = true;
            if (!afterDateDpe(dpeDate) && !dpeMntEstimEnergie)  isNotRequired = true;

            if (isNotRequired){
                enqueueSnackbar('Veuillez vérifier les valeurs dans DPE', { variant: 'error' });
                return null;
            }
            return params;
        } else {
            return { ...params, dpeAnneeRefPrixEnergie : '', dpeMntEstimEnergie : '', dpeMntEstimEnergieMin : '', dpeMntEstimEnergieMax: '', dpeDate : '', dpeGes : '', dpeKw : ''};
        }
    };

    const handleSubmit = (values) => {
        saveBien(values);
    };

    return (
        <Box className={classes.root}>
            { isSubmiting && <LinearProgress className={classes.progress} /> }
            <Paper elevation={2} className={classes.paper}>
                <Box className={classes.boxHeader}>
                <Typography variant="h6" className={classes.title}>Caractéristique de bien</Typography>
                    { !isDisabled && 
                        <Button form="form-caractere" variant="contained" color="primary" type="submit" className={classes.button}>Mettre à jour</Button>
                    }
                </Box>
                <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.form} id="form-caractere" >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Grid container direction="column">
                                <SurfaceDimensionSection form={form} hasValide={isDisabled} bienType={bienInfo.type} />
                                { is_not_terrain_stationnement(bienInfo.type) && 
                                    <ChauffageClimatisationSection form={form} hasValide={isDisabled} />
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            { is_not_terrain_stationnement(bienInfo.type) && <AmenagementSection form={form} hasValide={isDisabled} bienType={bienInfo.type} />}
                            { is_appartement_maison_localBureau(bienInfo.type) && <DpeSection form={form} hasValide={isDisabled} />}
                        </Grid>
                        { is_not_terrain_stationnement(bienInfo.type) && 
                            <Grid item xs={12} md={6} lg={4}>
                                <EquipementSection form={form} hasValide={isDisabled} />
                            </Grid>
                        }
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
}

export default Caracteristique;