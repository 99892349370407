import { alpha, Checkbox, makeStyles, TableCell, TableRow, Chip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArchiveIcon from '@material-ui/icons/Archive';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import MenuMore from '../../../components/MenuMore';
import { STATUS_BIEN_ARCHIVE, STATUS_BIEN_DIFFUSED, STATUS_BIEN_UNDIFFUSED, URL_DOCUMENT_GET } from '../../../constants';
import { getUserInfo, isAdmin, currencyEURFormat } from '../../../util';

BienItem.propTypes = {
    bien : PropTypes.object,
    onDiffuse : PropTypes.func,
    onRemove : PropTypes.func,
    onSelect : PropTypes.func,
    onUndiffuser : PropTypes.func,
    onArchiver : PropTypes.func,
};

const useStyles = makeStyles(theme => ({
    bgSel : {
        backgroundColor : alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
    },
    imageCover : {
        borderRadius : '2px',
    }
}));

function BienItem(props) {

    const history = useHistory();
    const { bien, onDiffuse, onRemove, onSelect, onUndiffuser, onArchiver } = props;
    const [ selected, setSelected ] = useState(false);
    const classes = useStyles();
    const urlPhoto = localStorage.getItem(URL_DOCUMENT_GET);

    const canConsulter = bien.status === STATUS_BIEN_ARCHIVE;
    const canModifier  = bien.status !== STATUS_BIEN_ARCHIVE;
    const canDiffuser = bien.status === STATUS_BIEN_UNDIFFUSED;
    const canUndiffuser = bien.status === STATUS_BIEN_DIFFUSED;
    const canSupprimer = bien.status === STATUS_BIEN_UNDIFFUSED && !bien.dateEndDiffusion;
    const canArchiver = bien.status === STATUS_BIEN_UNDIFFUSED && !!bien.dateEndDiffusion;

    const handleClickModif = () => {
        history.push(`/franchise/${bien.uuid}`);
    };

    const handleAction = (callBackAction) => {
        if (!callBackAction)   return;
        callBackAction(bien);
    };

    const handleChange = () => {
        if (onSelect)   onSelect(!selected, bien.uuid);
        setSelected(!selected);
    };

    const me = getUserInfo();
    const isMeOrAdmin = me.username === bien.userCreateName || isAdmin();

    const OPTIONS = [
        { title : 'Consulter', onClick : handleClickModif, icon : VisibilityIcon, color: 'secondary', show : !isMeOrAdmin || canConsulter},
        { title : 'Modifier', onClick : handleClickModif, icon : EditIcon, color: 'secondary', show : isMeOrAdmin && canModifier },
        { title : 'Diffuser', onClick : () => handleAction(onDiffuse), icon : DoubleArrowIcon, color: 'secondary', show : isMeOrAdmin && canDiffuser },
        { title : 'Stop diffusion', onClick : () => handleAction(onUndiffuser), icon : TimerOffIcon, color: 'secondary', show : isMeOrAdmin && canUndiffuser },
        { title : 'Archiver', onClick : () => handleAction(onArchiver), icon : ArchiveIcon, color: 'secondary', show : isMeOrAdmin && canArchiver },
        { title : 'Supprimer', onClick : () => handleAction(onRemove), icon : DeleteIcon, color: 'error', show : isMeOrAdmin && canSupprimer },
    ];

    
    let domaine;

    if (window.location.href.includes('qualif')){ 
        domaine = 'recette.';
    } else if (window.location.href.includes('preprod')){ 
        domaine = 'preprod.';
    } else {
        domaine = '';
    } 

    const priceFormatter = currencyEURFormat(bien.price);
    // eslint-disable-next-line no-nested-ternary
    const statusBien = bien.status === STATUS_BIEN_UNDIFFUSED ? {label : 'Non-diffusé', color : 'primary'} : bien.status === STATUS_BIEN_DIFFUSED ? {label : 'Diffusé', color : 'secondary'} : bien.status === STATUS_BIEN_ARCHIVE ? {label : 'Archivé'} : '';

    return (
        <TableRow hover className={selected ? classes.bgSel : ''}>
            <TableCell padding="checkbox">
                <Checkbox color="primary" checked={selected} onChange={handleChange} disabled={!isMeOrAdmin || !canDiffuser} />
            </TableCell>
            <TableCell size="small"> {bien.status === STATUS_BIEN_DIFFUSED ? <a href={`https://${domaine}fr.foncia.com/achat/-/-/${bien.reference}.htm`} target='_blank' rel="noreferrer">{bien.reference}</a> : bien.reference} </TableCell>
            <TableCell size="small">{bien.typeBien.label}</TableCell>
            <TableCell size="small">{bien.zipCode} - {bien.city}</TableCell>
            <TableCell size="small">{priceFormatter}</TableCell>
            <TableCell size="small">{bien.cover ? <a href={`${urlPhoto}${bien.cover.path}?s=xl`} target='_blank' rel="noreferrer"><img className={classes.imageCover} src={`${urlPhoto}${bien.cover.path}?s=xs`} srcSet={`${urlPhoto}${bien.cover.path}?s=xs`} alt={bien.cover.type} loading="lazy" height={20} /></a> : ''}</TableCell>
            <TableCell size="small" align="center">{statusBien && <Chip label={statusBien.label} color={statusBien.color} size="small" />}</TableCell>
            <TableCell size="small" align="center">
                <MenuMore options={OPTIONS} />
            </TableCell>
        </TableRow>
    );
}

export default BienItem;