import { useEffect, useState } from 'react';
import otherApi from '../api/otherApi';

export default function(inputValue){
  const [ adresses, setAdresses] = useState([]);
  
  useEffect(() => {
    (async () => {
      if (inputValue && inputValue.length){
        try {
          const res = await otherApi.getAdresse(inputValue);
          setAdresses(res.data.features);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [inputValue]);

  return adresses;
}