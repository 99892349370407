import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import authApi from '../../../api/authApi';
import { ERROR_USER_HAS_ACTIVE, ERROR_USER_NOT_EXISTE, ERROR_USER_PWD_CONTAIN_FIRST_LAST_NAME } from '../../../constants';
import { removeUserFromLocalStorage } from '../../../util';
import UpdateForm from '../components/UpdateForm';

UpdatePage.propTypes = {
};

const LIST_ERROR_FORM = {
    [ ERROR_USER_NOT_EXISTE ] : { message : 'Cet identifiant n\'existe pas. Veuillez vérifier, s\'il vous plaît!' },
    [ ERROR_USER_HAS_ACTIVE ] : { message : 'Vous avez déjà activé, veuillez connecter!' },
    [ ERROR_USER_PWD_CONTAIN_FIRST_LAST_NAME ] : { message : 'Le mot de passe ne doit pas contenir le nom ou le prénom, veuillez modifier l\'information' },
};

function UpdatePage() {

    removeUserFromLocalStorage();

    const location = useLocation();
    const history = useHistory();
    const [ isSubmitting, setSubmitting ] = useState(false);
    const { identifiant, email, hash } = queryString.parse(location.search);

    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = async (values, token) => {
        setSubmitting(true);
        try {
            await authApi.update(identifiant, { password : values.password, token, hash });
            enqueueSnackbar('Mettre à jour le mot de passe avec succès, veuillez connecter maintenant', { variant : 'success' });
            history.push('/auth/login');
        } catch (error) {
            const { code } = error;
            const msg = (code && LIST_ERROR_FORM[code]) ? LIST_ERROR_FORM[code].message : 'Une erreur est survenue en mise à jour le mot de passe.';
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <UpdateForm identifiant={identifiant} email={email} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
    );
}

export default UpdatePage;