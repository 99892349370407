import React from 'react';
import MenuFranchise from '../MenuFranchise';

EditPage.propTypes = {
};

function EditPage() {

    return (
        <MenuFranchise />
    );
}

export default EditPage;