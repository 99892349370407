import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { CURRENCYS, HOUSING_TYPE_APPARTEMENT, STATUS_BIEN_DIFFUSED, STATUS_BIEN_UNDIFFUSED } from '../../../constants';
import { getUserInfo, isAdmin, is_not_terrain } from '../../../util';
import { putData } from '../bienSlice';
import Caracteristique from '../Caracteristique';
import Detail from '../Detail';
import General from '../General';
import Photo from '../Photo';
import bienApi from './../../../api/bienApi';
import Popup from './../../../components/Popup';
import TabHeader from './../../../components/TabPanel/TabHeader';

MenuFranchise.propTypes = {
};

const useStyles = makeStyles((theme) => ({
    item : {
        marginTop : theme.spacing(3),
    },
    button : {
        textTransform : 'none',
        marginRight : theme.spacing(1),
    },
    buttonDanger : {
        backgroundColor : theme.palette.error.main,
        '&:hover' : {
            backgroundColor : theme.palette.error.main,
        }
    }
}));

const getMenuWithBienType = (bienType) => {
    const showDetail = bienType ? is_not_terrain(bienType) : true;
    
    return {
        GENERAL : { label : 'Général', component : General, disabled : false, show : true, },
        DETAIL : { label : 'Détails', component : Detail, disabled : true, show : showDetail, },
        CARACTERISTIQUE : { label : 'Caractéristiques du bien', component : Caracteristique, disabled : true, show : true, },
        PHOTO : { label : 'Photo', component : Photo, disabled : true, show : true, },
    };
};

function MenuFranchise() {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { bienUuid } = useParams();
    const [ ready, setReady ] = useState(false);
    const history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ bien, setBien ] = useState(null);
    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});
    const dispatch = useDispatch();

    const me = getUserInfo();
    const isMeOrAdmin = (bien && me.username === bien.userCreateName) || isAdmin();

    console.log(loading);

    const menuList = bien 
    ? Object.values(getMenuWithBienType(bien.type)).map(item => ({ ...item, disabled : false })).filter(item => item.show)
    : Object.values(getMenuWithBienType());

    const dispatchToContext = (values) => {
        const action = putData(values);
        dispatch(action);
    };

    const getbien = async () => {
        if (!bienUuid)  return;
        try {
            const res = await bienApi.get(bienUuid);
            setBien(res);
            return res;
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Une erreur est survenue de téléchargement bien', { variant: 'error' });
            history.push('/');
        }
    };

    const { diffuse, undiffuser, delete : deleteBien, archiver } = bienApi;
    const ACTION_BIEN = {
        DIFFUSER : { callApiFunc : diffuse, actionLabel : 'Diffuser', buttonLabel : 'Diffuser', show : bien && bien.status === STATUS_BIEN_UNDIFFUSED, code : 'DIFFUSER', callBackFunc : getbien},
        UNDIFFUSER : { callApiFunc : undiffuser, actionLabel : 'Arrête de diffusion', buttonLabel : 'Arrêter', show : bien && bien.status === STATUS_BIEN_DIFFUSED, code : 'UNDIFFUSER', callBackFunc : getbien, style : classes.buttonDanger},
        DELETE : { callApiFunc : deleteBien, actionLabel : 'Supprimer', buttonLabel : 'Supprimer', show : bien && bien.status === STATUS_BIEN_UNDIFFUSED && !bien.dateEndDiffusion, code : 'DELETE', callBackFunc : () => history.push('/'), style : classes.buttonDanger},
        ARCHIVER : { callApiFunc : archiver, actionLabel : 'Archiver', buttonLabel : 'Archiver', show : bien && bien.status === STATUS_BIEN_UNDIFFUSED && !!bien.dateEndDiffusion, code : 'ARCHIVER', callBackFunc : () => history.push('/')},
    };

    const handleAction = (actionCode) => {
        const { actionLabel, buttonLabel } = ACTION_BIEN[actionCode];
        setOpenPopup(true);
        setParamPopup({
            title : `${actionLabel} ce bien`,
            content : <>{`${actionLabel} ce bien`}</>,
            buttons : [
                {label : buttonLabel, color : 'primary', onClick : () => actionBien(actionCode)},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const actionBien = async (actionCode) => {
        if (!bienUuid)  return;
        setLoading(true);
        const { callApiFunc, actionLabel, callBackFunc } = ACTION_BIEN[actionCode];
        try {
            await callApiFunc(bienUuid);
            enqueueSnackbar(`${actionLabel} le bien avec succès`, { variant: 'success' });
            if (callBackFunc)   callBackFunc();
        } catch (error) {
            enqueueSnackbar(`Ne pas pouvoir de ${actionLabel.toLowerCase()} ce bien en ce moment, Veuillez réessayer ultérieurement`, { variant: 'error' });
            console.log(`fail to ${actionLabel.toLowerCase()} ce bien`, error);
        } finally {
            setLoading(false);
            setOpenPopup(false);
            setParamPopup({});
        }
    };

    useEffect(() => {
        setReady(false);
        if (bienUuid){
            (async () => {
                const res = await getbien();
                dispatchToContext(res);
                setReady(true);
            })();
        } else {
            dispatchToContext({ type : HOUSING_TYPE_APPARTEMENT, currency : CURRENCYS.EUR.value, releaseDate : null, });
            setReady(true);
        }
    }, [bienUuid]);
    
    return (
        <Box>
            { ready && <>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} className={classes.item}>
                        <TabHeader menuList={menuList} 
                            actionAdd = { (bien && isMeOrAdmin) && <>
                                {Object.values(ACTION_BIEN).filter(item => item.show).map((item, index) => (
                                    <Button key={index} className={`${classes.button} ${item.style ? item.style : ''}`} variant="contained" color="primary" size="small" onClick={() => handleAction(item.code)}>
                                        {item.actionLabel}
                                    </Button>
                                ))}
                            </>} 
                        />
                    </Grid>
                </Grid>
                <Popup open={openPopup} {...paramPopup} />
            </>}
        </Box>
    );
}

export default MenuFranchise;