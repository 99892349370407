import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

MenuMore.propTypes = {
    options : PropTypes.array,
    ord : PropTypes.number,
    colorIcon : PropTypes.string,
    width : PropTypes.string,
};

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
    icon : {
        padding : 0,
    },
    margin: {
        marginRight: theme.spacing(1),
    },
    item : {
        fontSize : 'inherit'
    }
}));

function MenuMore({ options = [], ord = 0, colorIcon = 'inherit', width = '20ch' }) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id={`long-button-${ord}`}
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.icon}
                style={{color : colorIcon}}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id={`long-menu-${ord}`}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width
                    },
                }}
            >
                {options.filter(item => item.show).map(({ title, onClick, icon : IconItem, color }, index) => (
                    <MenuItem className={classes.item} key={index} onClick={() => {
                        if (onClick) onClick();
                        handleClose();
                    }}>
                        <Tooltip title={title}>
                            <IconButton aria-label={title} size="small" className={classes.margin}>
                                <IconItem color={color} fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        {title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default MenuMore;