import { createSlice } from '@reduxjs/toolkit';
import { CURRENCYS, HOUSING_TYPE_APPARTEMENT } from '../../constants';

const bienSlice = createSlice({
    name : 'bien',
    initialState : {
        type : HOUSING_TYPE_APPARTEMENT,
        currency : CURRENCYS.EUR.value,
        releaseDate : null,
    },
    reducers : {
        putData(state, action){
            return { ...action.payload };
        },
        setData(state, action){
            return { ...state, ...action.payload };
        }
    }
});

const { actions, reducer } = bienSlice;

export const { putData, setData } = actions;
export default reducer;