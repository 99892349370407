import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import Ajouter from '../Ajouter';
import Recherche from '../Recherche';
import TabHeader from './../../../components/TabPanel/TabHeader';

MenuAdmin.propTypes = {
};

const useStyles = makeStyles((theme) => ({
    item : {
        marginTop : theme.spacing(3),
    },
}));

const menuList = [
    { label : 'Rechercher', component : Recherche },
    { label : 'Ajouter', component : Ajouter },
];

function MenuAdmin() {

    const classes = useStyles();

    return (
        <Box>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} className={classes.item}>
                    <TabHeader menuList={menuList} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default MenuAdmin;