import PropTypes from 'prop-types';
import React from 'react';
import FileField from './../../../../../components/form-controls/FileField';

PhotoForm.propTypes = {
    form : PropTypes.any,
    onChange : PropTypes.func,
};


function PhotoForm(props) {

    const { form, onChange } = props;

    return (
        <FileField name="doc" multiple label="Ajouter Photo" form={form} onChange={onChange} />
    );
}

export default PhotoForm;