import { Container, makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useState } from 'react';
import { Switch } from 'react-router-dom';
import Administration from '../../features/Administration';
import Franchise from '../../features/Franchise';
import Home from '../../features/Home';
import Profile from '../../features/Profile';
import Header from '../Header';
import Logo from '../Logo';
import NotFound from '../NotFound';
import Sidebar from './../Sidebar';
import PrivateRouteItem from './PrivateRouteItem';

const useStyles = makeStyles((theme) => ({
    root : {
        display : 'flex',
        height : '100vh',
        backgroundColor : 'rgb(244, 246, 248)',
    },
    content : {
        flexGrow: 1,
        width : '100%',
    },
    toolbar: theme.mixins.toolbar,
}));

function PrivateRoute() {

    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header onToggle={handleDrawerToggle} />
            <Logo />
            <Sidebar open={mobileOpen} onToggle={handleDrawerToggle} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container>
                    <Switch>
                        <PrivateRouteItem exact path="/" component={Home} />
                        <PrivateRouteItem exact path="/administration" component={Administration} />
                        <PrivateRouteItem path="/franchise" component={Franchise} />
                        <PrivateRouteItem exact path="/profil" component={Profile} />
                        <PrivateRouteItem component={NotFound} />
                    </Switch>
                </Container>
            </main>
        </div>
    );
}

export default PrivateRoute;