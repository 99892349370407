import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Box, makeStyles } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { URL_DOCUMENT_GET } from '../../constants';
import './SliderSlick.css';

SliderSlick.propTypes = {
    photos : PropTypes.array,
    photoShow : PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
    root : {
        padding: theme.spacing(5),
        background: 'rgb(244, 246, 248)',
    },
    imgItem : {
        margin : 'auto',
        borderRadius : '5px',
    }
}));

function SliderSlick(props) {

    const { photos = [], photoShow, ...params  } = props;
    const classes = useStyles();

    const urlPhoto = localStorage.getItem(URL_DOCUMENT_GET);

    const settings = {
        dots: true,
        infinite : true,
        speed : 500,
        initialSlide : photos.findIndex(item => item.id === photoShow.id),
        slidesToScroll: 1,
        ...params,
    };

    return (
        <div className={classes.root}>
            <Slider {...settings}>
                {
                    photos.map((item, index) => (
                        <Box key={index}>
                            <img src={`${urlPhoto}${item.path}?s=l`} className={classes.imgItem} />
                        </Box>
                    ))
                }
            </Slider>
      </div>
    );
}

export default SliderSlick;