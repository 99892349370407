import { Box, Grid } from '@material-ui/core';
import React from 'react';
import FileItem from '../../../../../components/form-controls/FileField/FileItem';
import PropTypes from 'prop-types';

PhotoReview.propTypes = {
    files : PropTypes.array,
    onRemove : PropTypes.func,
};

function PhotoReview(props) {

    const { files = [], onRemove } = props;
    
    return (
        <Box>
            <Grid container spacing={2}>
                { files.map((file, i) => (
                    <FileItem key={i} file={{ ...file, file: URL.createObjectURL(file.file) }} onRemove={() => onRemove(i)} />
                )) }
            </Grid>
        </Box>
    );
}

export default PhotoReview;