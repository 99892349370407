import { isDate } from 'date-fns';
import moment from 'moment';

const formatDate = (date, format = 'YYYY-MM-DDTHH:mm:ss') => {
    const newDate = moment(date);
    if (!newDate.isValid()) return null;
    return newDate.locale('fr').format(format);
};

const parseDate = (value, originalValue) => {
    if (!originalValue) return null;
    if (isDate(originalValue)) return originalValue;

    return new Date(originalValue);
};

const afterDateDpe = (date) => {
    if (!date)  return false;
    const dateDpe = new Date(2021, 6, 1);
    return date.getTime() > dateDpe.getTime();
};

export { formatDate, parseDate, afterDateDpe };
