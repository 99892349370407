import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, LinearProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { STATUS_BIEN_ARCHIVE } from '../../../constants';
import { getUserInfo, isAdmin, is_not_terrain_stationnement,is_not_terrain, parseDate } from '../../../util';
import { setData } from '../bienSlice';
import bienApi from './../../../api/bienApi';
import ChargeTaxeSection from './ChargeTaxeSection';
import EstimationSection from './EstimationSection';
import CoproprieteSection from './CoproprieteSection';
import GeneralSection from './GeneralSection';
import LocalisationSection from './LocalisationSection';

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(4),
        fontWeight : 'bold',
        fontSize : theme.typography.h6.fontSize,
    },
    form : {
        margin : theme.spacing(1),
    },
    paper : {
        padding : theme.spacing(2),
    },
    button : {
        textTransform : 'none',
    },
    boxHeader : {
        display : 'flex',
        justifyContent : 'space-between',
        padding : `0 ${theme.spacing(1)}`,
    },
    progress : {
        position : 'absolute',
        top : theme.spacing(8),
        left : 0,
        right: 0,
    },
    title : {
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    }
}));

function General() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const bienInfo = useSelector((state) => state.bien);
    const [ bienType, setBienType ] = useState(bienInfo.type);

    const { enqueueSnackbar } = useSnackbar();
    const [ isSubmiting, setSubmiting ] = useState(false);
    const history = useHistory();

    const hasUuid = !!bienInfo.uuid;
    const schema = yup.object().shape(SHAPE);

    const isValid = hasUuid && bienInfo.status === STATUS_BIEN_ARCHIVE;

    const me = getUserInfo();
    const isMeOrAdmin = (hasUuid && me.username === bienInfo.userCreateName) || isAdmin();

    // eslint-disable-next-line no-irregular-whitespace
    const isDisabled = hasUuid && (isValid || !isMeOrAdmin);

    const form = useForm({
        defaultValues: {...bienInfo, streetNumber : bienInfo.streetNumber ? `${bienInfo.streetNumber}, ${bienInfo.zipCode}, ${bienInfo.city}` : ''},
        resolver : yupResolver(schema),
    });

    const createBien = async (values) => {
        try {
            const params = { ...bienInfo, ...values};
            const bien = await bienApi.create(params);

            enqueueSnackbar('Création le bien avec succès', { variant: 'success' });
            history.push(`/franchise/${bien.uuid}`);
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Une erreur est survenue dans la création un bien', { variant: 'error' });
        } finally {
            setSubmiting(false);
        }
    };

    const updateBien = async (values) => {
        try {
            const params = { ...bienInfo, ...values};
            ['id', 'typeBien', 'globalState', 'kitchenState', 'heatingType', 'heatingNature', 'dpeStatut', 'status', 'active', 'uuid', 'reference', 'userCreateName', 'dateEndDiffusion', 'photos'].forEach(item => delete params[item]);
            const bien = await bienApi.update(bienInfo.uuid, params);

            enqueueSnackbar('Mettre à jour le bien avec succès', { variant: 'success' });
            const action = setData(bien);
            dispatch(action);
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Une erreur est survenue dans la création un bien', { variant: 'error' });
        } finally {
            setSubmiting(false);
        }
    };

    const handleSubmit = (values) => {
        setSubmiting(true);
        hasUuid ? updateBien(values) : createBien(values);
    };

    const handleChange = (type) => {
        setBienType(type);
    };

    return (
        <Box className={classes.root}>
            { isSubmiting && <LinearProgress className={classes.progress} /> }
            <Paper elevation={2} className={classes.paper}>
                <Box className={classes.boxHeader}>
                    <Typography variant="h6" className={classes.title}>{ hasUuid ? 'Mettre à jour le bien' : 'Nouvelle Saisie de bien' }</Typography>
                    { !isDisabled && 
                        <Button form="form-general" variant="contained" color="primary" type="submit" className={classes.button}>{ hasUuid ? 'Mettre à jour' : 'Ajouter' }</Button>
                    }
                </Box>
                <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.form} id="form-general" >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <Grid container direction="column">
                                <LocalisationSection form={form} hasValide={isDisabled} />
                                <EstimationSection form={form} hasValide={isDisabled} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <GeneralSection form={form} hasValide={isDisabled} onChange={handleChange} bienType={bienType} />
                        </Grid>
                        
                            <Grid item xs={12} lg={4}>
                            { is_not_terrain_stationnement(bienType) && 
                                <ChargeTaxeSection form={form} hasValide={isDisabled} />
                           }
                           { is_not_terrain(bienType) && 
                                    <CoproprieteSection form={form} hasValide={isDisabled} />
                                }
                                {
                                    //<ZoneSoumisEncadLoyer form={form} hasValide={isDisabled} /> 
                                }
                            </Grid>
                            
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
}

const SHAPE = {
    //Partie Général
    libelle : yup.string().nullable(),
    type : yup.number().required('Veuillez choisir le type du bien'),
    glState : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    surface : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    roomCount : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    commercialPerimises : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    bathroomCount : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    showerroomCount : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    wcCount : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    appartmentCount : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    occupiedAppartmentCount : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    emptyAppartmentCount : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    buildingCount : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    cellarCount : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    description : yup.string().nullable(),
    releaseDate : yup.date().transform(parseDate).nullable(),
    typeMandat : yup.number().nullable(),

    //partie localisation
    hood : yup.string().nullable(),
    streetNumber : yup.string().required('Veuillez saisir adresse'),
    complement : yup.string().nullable(),
    geoLocal : yup.bool().nullable(),
    zipCode : yup.number().required('Veuillez saisir code postal').min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    city : yup.string().required('Veuillez saisir ville'),
    latitude : yup.number().required('Veuillez saisir le lattitude'),
    longitude : yup.number().required('Veuillez saisir le longtitude'),

    //partie charge et taxes
    globalCharges : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    monthlyCharge : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    chargeNature : yup.string().nullable(),
    propertyTax : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    housingTax : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),

     //partie copropriété
    nombreLot : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    procedure : yup.bool().nullable(),

    //partie estimation
    price : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    honorary : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    honoraryHt : yup.number().nullable().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro'),
    currency : yup.string(),
};

export default General;