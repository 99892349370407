import React from 'react';
import MenuAdmin from './MenuAdmin';

Administration.propTypes = {
    
};

function Administration() {

    return (
        <MenuAdmin />
    );
}

export default Administration;