import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';

AutoCompleteField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    size : PropTypes.string,
    variant : PropTypes.string,

    list : PropTypes.array,
    onChangeList : PropTypes.func,
    updateTrigger : PropTypes.func,
};

const useStyles = makeStyles(() => ({
    root : {
        marginTop : 0,
        marginBottom : 0,
    }
}));

function AutoCompleteField(props) {
    const { form, name, label, disabled, size = 'small', variant = 'outlined', list = [], onChangeList, updateTrigger } = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];

    const classes = useStyles();

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
                <Autocomplete
                    freeSolo
                    id={`autocomplete_${name}`}
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    options={list}
                    onChange={(event, newValue) => {
                        onChange({ ...event, target : { name, value : newValue.value ? newValue.value : newValue.label } });
                        updateTrigger && updateTrigger(newValue);
                    }}
                    loadingText="Chargement ..."
                    defaultValue={{value, label : value, id : `autocomplete_${name}`}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                            margin="normal"
                            variant={variant}
                            fullWidth
                            label={label}
                            disabled={disabled}
                            error={!!hasError}
                            helperText={errors[name]?.message}
                            name={name}
                            value={value}
                            onChange={(e) => {
                                if (onChangeList)    onChangeList(e.target.value);
                            }}
                            onBlur={onBlur}
                            size={size}
                            className={classes.root}
                        />
                    )}
                />
            )}
        />
    );
}

export default AutoCompleteField;