import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import paramApi from '../api/paramApi';

export default function(){
  const [ types, setTypes] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        const typeBiens = await paramApi.typeBiens();
        setTypes(typeBiens);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ne peut pas télécharger les types de bien', { variant: 'error' });
      }
    })();
  }, []);

  return types;
}