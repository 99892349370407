import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import InputField from '../../../../components/form-controls/InputField';
import SelectField from '../../../../components/form-controls/SelectField';
import { ROLES_CODES } from '../../../../constants';
import useAgence from '../../../../hook/useAgence';
import useRoles from '../../../../hook/useRoles';
import { isSuperAdmin } from '../../../../util';

UserFormModif.propTypes = {
    user : PropTypes.object,
    onSubmit : PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    form : {
        margin : theme.spacing(1),
    },
}));

function UserFormModif(props) {

    const { user, onSubmit } = props;
    const classes = useStyles();

    const roles = useRoles();
    const agences = useAgence() || [];
    const isSuperAdm = isSuperAdmin();

    const schema = yup.object().shape({
        firstName: yup.string().required('Veuillez saisir le prénom'),
        lastName: yup.string().required('Veuillez saisir le nom de famille'),
        email : yup.string().required('Veuillez saisir l\'e-mail').email('Veuillez entrer un email valide'),
        roleCode: yup.string(),
    });

    const form = useForm({
        defaultValues: {
            firstName : user.firstName,
            lastName : user.lastName,
            email : user.email,
            roleCode : user.roles[0].code,
            agenceId : user.agence?.id,
            active : user.active,
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit({ ...user, ...values });
    };

    return (
        <Box className={classes.root}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.form} id="form-modify" >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <InputField name="email" label="Email" form={form} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputField name="firstName" label="Prénom" form={form} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputField name="lastName" label="Nom" form={form} variant="outlined" />
                            </Grid>
                            {
                                isSuperAdm && <>
                                    <Grid item xs={12}>
                                        <SelectField  name="roleCode" label="Rôle" form={form} items={roles.filter(role => role.code !== ROLES_CODES.SUPER_ADM)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectField name="agenceId" label="Agence" form={form} items={agences} />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

export default UserFormModif;