import bienReducer from '../features/Franchise/bienSlice';
import { configureStore } from '@reduxjs/toolkit';

const routeReducer = {
    bien : bienReducer,
};

const store = configureStore({
    reducer : routeReducer,
});

export default store;