import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, LinearProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import bienApi from '../../../api/bienApi';
import { STATUS_BIEN_ARCHIVE } from '../../../constants';
import { getUserInfo, isAdmin, is_not_stationnement, is_not_terrain_stationnement } from '../../../util';
import { setData } from '../bienSlice';
import AnnexeSection from './AnnexeSection/Index';
import ImmeubleSection from './ImmeubleSection';
import StationnementSection from './StationnementSection';

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(4),
        fontWeight : 'bold',
        fontSize : theme.typography.h6.fontSize,
    },
    form : {
        margin : theme.spacing(2),
    },
    paper : {
        padding : theme.spacing(2),
    },
    button : {
        textTransform : 'none',
    },
    boxHeader : {
        display : 'flex',
        justifyContent : 'space-between',
        padding : `0 ${theme.spacing(1)}`,
    },
    progress : {
        position : 'absolute',
        top : theme.spacing(8),
        left : 0,
        right: 0,
    },
    title : {
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    }
}));

function Detail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const bienInfo = useSelector((state) => state.bien);
    const { enqueueSnackbar } = useSnackbar();
    const [ isSubmiting, setSubmiting ] = useState(false);
    const isValid = bienInfo.status === STATUS_BIEN_ARCHIVE;

    const me = getUserInfo();
    const isMeOrAdmin = me.username === bienInfo.userCreateName || isAdmin();

    // eslint-disable-next-line no-irregular-whitespace
    const isDisabled = isValid || !isMeOrAdmin;

    const schema = yup.object().shape(SHAPE);
    const form = useForm({
        defaultValues: { ...bienInfo },
        resolver : yupResolver(schema),
    });

    const saveBien = async (values) => {
        setSubmiting(true);
        try {
            const params = { ...bienInfo, ...values};
            ['id', 'status', 'active', 'uuid', 'reference', 'userCreateName', 'dateEndDiffusion', 'photos'].forEach(item => delete params[item]);
            const bien = await bienApi.update(bienInfo.uuid, params);
            enqueueSnackbar('Mettre à jour le bien avec succès', { variant: 'success' });
            const action = setData(bien);
            dispatch(action);
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Une erreur est survenue dans la mise à jour un bien', { variant: 'error' });
        } finally {
            setSubmiting(false);
        }
    };

    const handleSubmit = (values) => {
        saveBien(values);
    };

    return (
        <Box className={classes.root}>
            { isSubmiting && <LinearProgress className={classes.progress} /> }
            <Paper elevation={2} className={classes.paper}>
                <Box className={classes.boxHeader}>
                    <Typography variant="h6" className={classes.title}>Détails Saisie de bien</Typography>
                    { !isDisabled && 
                        <Button form="form-detail" variant="contained" color="primary" type="submit" className={classes.button}>
                            Mettre à jour
                        </Button>
                    }
                </Box>
                <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.form} id="form-detail" >
                    <Grid container spacing={2}>
                        { is_not_stationnement(bienInfo.type) && 
                            <Grid item xs={12} md={6} lg={4}>
                                <ImmeubleSection form={form} hasValide={isDisabled} bienType={bienInfo.type} />
                            </Grid>
                        }
                        <Grid item xs={12} md={6} lg={4}>
                            <StationnementSection form={form} hasValide={isDisabled} bienType={bienInfo.type} />
                        </Grid>
                        { is_not_terrain_stationnement(bienInfo.type) && 
                            <Grid item xs={12} md={6} lg={4}>
                                <AnnexeSection form={form} hasValide={isDisabled} />
                            </Grid>
                        }
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
}

const SHAPE = {
    constructionYear : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    floorCount : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    floor : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    lift : yup.bool().nullable(),
    digicode : yup.bool().nullable(),
    interphone : yup.bool().nullable(),
    rdc : yup.bool().nullable(),
    cleaning : yup.bool().nullable(),
    handicapAccess : yup.bool().nullable(),
    cable : yup.bool().nullable(),
    gardian : yup.bool().nullable(),
    videoPhone : yup.bool().nullable(),

    boxCount : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    internalParking : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    externalParking : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    parkingCount : yup.number().min(0, 'Valeur minimal est 0').typeError('Veuillez saisir un numéro').nullable(),
    bikeRoom : yup.bool().nullable(),

    annexStudio : yup.bool().nullable(),
    pool : yup.bool().nullable(),
};

export default Detail;