export const HOUSING_TYPE_APPARTEMENT = 1;
export const HOUSING_TYPE_MAISON = 2;
export const HOUSING_TYPE_TERRAIN = 3;
export const HOUSING_TYPE_STATIONNEMENT = 4;
export const HOUSING_TYPE_IMMEUBLE = 5;
export const HOUSING_TYPE_LOCAL_BUREAU = 6;

export const HEATING_ENERGY_GAZ = 1;
export const HEATING_ENERGY_ELECTRIQUE = 2;
export const HEATING_ENERGY_FUEL = 3;
export const HEATING_ENERGY_MIXTE = 4;

export const HEATING_TYPE_INDIVIDUEL = 1;
export const HEATING_TYPE_COLLECTIF = 2;

export const CURRENCYS = {
    EUR : { label : 'Euro (€)', value : 'EUR' },
    USD : { label : 'US Dollar ($)', value : 'USD' },
};