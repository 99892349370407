const STORAGES_KEYS = {
    USER : 'user',
    TOKEN : 'access_token'
};

const ROLES_CODES = {
    SUPER_ADM : 'SUPER_ADM',
    AGENCE_ADM : 'AGENCE_ADM',
    UTILISATEUR : 'USER',
};

const URL_DOCUMENT_GET = 'URL_DOCUMENT_GET';

export { ROLES_CODES, URL_DOCUMENT_GET };

export default STORAGES_KEYS;