import { Box, Button, Grid, makeStyles, Paper, TablePagination, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import bienApi from '../../api/bienApi';
import { STATUS_BIEN_ARCHIVE, STATUS_BIEN_DIFFUSED, STATUS_BIEN_UNDIFFUSED } from '../../constants';
import BienFilter from './components/BienFilter';
import BienList from './components/BienList';
import BienSkeletonList from './components/BienSkeletonList';

Home.propTypes = {
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(5),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    pagination : {
      display : 'flex',
      flexFlow : 'row nowrap',
      justifyContent : 'center',

      marginTop : '20px',
      paddingBottom : '10px',
    },
    boxButton : {
        marginRight : theme.spacing(1),
        display : 'flex',
        justifyContent : 'flex-end',
    },
    paper : {
        padding : theme.spacing(2),
    },
    button : {
        textTransform : 'none',
    },
    title : {
        fontWeight : 'bold',
    }
}));

const ROW_OF_PAGE_DEFAULT = 10;

function Home() {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const queryParam = useMemo(() => {
        const params = queryString.parse(location.search);
  
        return {
          ...params,
          page : Number.parseInt(params.page) || 0,
          size : Number.parseInt(params.size) || ROW_OF_PAGE_DEFAULT,
          status : params.status || [ STATUS_BIEN_DIFFUSED, STATUS_BIEN_ARCHIVE, STATUS_BIEN_UNDIFFUSED ],
        };
    }, [location.search]);

    const { enqueueSnackbar } = useSnackbar();
    const [ bienList, setBienList ] = useState([]);
    const [ rowsPerPage, setRowsPerPage ] = useState(ROW_OF_PAGE_DEFAULT);

    const [ pagination, setPagination ] = useState({ count : 1, page : 0 });
    const [ loading, setLoading ] = useState(false);
    const [ filters, setFilters ] = useState(() => ({ page : 0, size : ROW_OF_PAGE_DEFAULT }));

    useEffect(() => {
        getListBien();
    }, [queryParam]);

    const getListBien = async () => {
        setLoading(true);
        try {
            const { page, size, ...params } = queryParam;
            const { status } = params;
            if (status && typeof status === 'string')    params.status = [status];
            const { items, currentPage, totalItems } = await bienApi.list({ page, size, params });
            setBienList(items);
            setPagination({ count : totalItems, page : currentPage });
        } catch (error) {
            enqueueSnackbar('Une erreur est survenue sur la récupération la liste du bien', {variant: 'error'});
            console.log('fail to get list bien', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilter) => {
        setFilters({...filters, ...newFilter });
    };

    const pushNewParams = newfilters => {
        history.push({
            pathname : history.location.pathname,
            search : queryString.stringify(newfilters),
        });
    };

    const handlePageChange = (e, page) => {
        const newfilters = {...queryParam, page };
        pushNewParams(newfilters);
    };

    const handleClick = () => {
        const newfilters = {...queryParam, page : 0, ...filters };
        pushNewParams(newfilters);
    };

    const handleChangeRowsPerPage = (event) => {
        const size = parseInt(event.target.value, 10);
        const newfilters = {...queryParam, size, page : 0 };
        pushNewParams(newfilters);
        setRowsPerPage(size);
    };

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box className={classes.root}>
                        <Paper elevation={1} className={classes.paper}>
                            <Typography variant="h6" className={classes.title}>{'Rechercher un bien'}</Typography>
                            <BienFilter onChange={handleFilterChange} />
                            <Box className={classes.boxButton}>
                                <Button onClick={handleClick} variant="contained" color="primary" className={classes.button}>Lancer la recherche</Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Paper elevation={1} className={classes.paper}>
                        {loading 
                        ? <BienSkeletonList length={rowsPerPage} /> 
                        : <>
                            <BienList data={bienList} callback={getListBien} />
                            <Box className={classes.pagination} >
                                <TablePagination 
                                    rowsPerPageOptions={[10, 20, 50]}
                                    component="div"
                                    count={pagination.count}
                                    rowsPerPage={rowsPerPage}
                                    page={pagination.page}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Nombre de ligne sur page"
                                />
                            </Box>
                        </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Home;