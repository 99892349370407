import React from 'react';
import MenuFranchise from '../MenuFranchise';

NewPage.propTypes = {
    
};

function NewPage() {
    return (
        <MenuFranchise />
    );
}

export default NewPage;