import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Container, CssBaseline, LinearProgress, Link, makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import InputField from '../../../../components/form-controls/InputField';
import PasswordField from '../../../../components/form-controls/PasswordField';
import useCaptcha from '../../../../hook/useCaptcha';
import logo from './../../../../assets/images/Foncia_logoh_66_66.png';

const useStyles = makeStyles(theme => ({
    root : {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit : {
        margin : theme.spacing(3, 0, 2, 0),
    },
    progress : {
        position : 'absolute',
        top : theme.spacing(1),
        left : 0,
        right: 0,
    },
    paper : {
        marginTop : theme.spacing(6),
        padding : theme.spacing(3),
    },
    form : {
        marginTop : theme.spacing(6)
    }
}));

LoginForm.propTypes = {
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

function LoginForm(props) {

    const { onSubmit, isSubmitting } = props;
    const classes = useStyles();
    const captcha = useCaptcha();

    const schema = yup.object().shape({
        username : yup.string().required('Veuillez saisir votre identifiant'),
        password : yup.string().required('Veuillez saisir votre mot de passe'),
    });

    const form = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(captcha, { action: 'submit' });
                onSubmit && onSubmit(values, token);
            } catch (error) {
                console.log(error);
            }
        });
    };

    return (<>
        {
            captcha && 
            <Container component="main" maxWidth="xs">
                <Paper elevation={1} className={classes.paper}>
                    <CssBaseline />
                    <div className={classes.root}>
                        { isSubmitting && <LinearProgress className={classes.progress} /> }
                        <Box><img src={logo} alt="Les franchisés" /></Box>
                        <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.form}>
                            <InputField name="username" label="Identifiant" form={form} />
                            <PasswordField name="password" label="Password" form={form} />

                            <Button disabled={isSubmitting} type="submit" className={classes.submit} variant='contained' color='primary' fullWidth size="medium">
                                Connexion
                            </Button>
                            <Link href="/auth/initpwd" variant="body2">Mot de passe oublié?</Link>
                        </form>
                    </div>
                </Paper>
            </Container>
        }
    </>);
}

export default LoginForm;
