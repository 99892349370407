import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ERROR_CODE_EMAIL, ERROR_CODE_IDENTIFIANT } from '../../../constants';
import { ROLES_CODES } from '../../../constants/storages-keys';
import { isSuperAdmin } from '../../../util';
import userApi from './../../../api/userApi';
import UserAddForm from './UserAddForm';
// import PropTypes from 'prop-types';

Ajouter.propTypes = {
    
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(4),
        fontWeight : 'bold',
        fontSize : theme.typography.h6.fontSize,
    },
    form : {
        margin : theme.spacing(1),
    },
    paper : {
        padding : theme.spacing(2),
    },
    boxButton : {
        display : 'flex',
        justifyContent : 'flex-end',
    },
    button : {
        textTransform : 'none',
    },
    title : {
        fontWeight : 'bold',
    }
}));

const LIST_ERROR_FORM = {
    [ ERROR_CODE_IDENTIFIANT ] : { field : 'identifiant', msg : { type : 'manual', message : 'Identifiant est existé. Veuillez choisir une autre' }},
    [ ERROR_CODE_EMAIL ] : { field : 'email', msg : { type : 'manual', message : 'Email est existé. Veuillez choisir une autre' }},
};

const DEFAULT_VALUES = { identifiant: '', firstName: '', lastName: '', email : '', roleCode: ROLES_CODES.UTILISATEUR, agenceId : null };

function Ajouter() {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [ isSubmiting, setSubmiting ] = useState(false);

    const isSuperAdm = isSuperAdmin();

    console.log('isSubmiting', isSubmiting);

    const schema = yup.object().shape({
        identifiant: yup.string().required('Veuillez saisir votre identifiant'),
        firstName: yup.string().required('Veuillez saisir le prénom'),
        lastName: yup.string().required('Veuillez saisir le nom de famille'),
        email : yup.string().required('Veuillez saisir l\'email').email('Veuillez saisir un e-mail valide'),
        roleCode: yup.string(),
        agenceId: isSuperAdm ? yup.number().required('Veuillez choisir votre agence') : yup.number().nullable(),
    });

    const form = useForm({
        defaultValues: DEFAULT_VALUES,
        resolver : yupResolver(schema),
    });

    const handleSubmit = async (values) => {
        setSubmiting(true);
        try {
            const response = await userApi.addUser(values);
            enqueueSnackbar('Création avec succès', { variant: 'success' });
            form.reset({ ...DEFAULT_VALUES });
            console.log(response);
        } catch (error) {
            const { code } = error;
            if (code){
                form.setError(LIST_ERROR_FORM[code].field, LIST_ERROR_FORM[code].msg);
                enqueueSnackbar(error.message, { variant: 'error' });
            } else {
                enqueueSnackbar('Une erreur est survenue, veuillez ressayer après.', { variant: 'error' });
            }
        } finally {
            setSubmiting(false);
        }
    };

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Nouvelle utilisateur</Typography>
                <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.form} >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container>
                                <UserAddForm form={form} />
                            </Grid>
                        </Grid>{}
                        <Grid item xs={12} className={classes.boxButton}>
                            <Button variant="contained" color="primary" type="submit" className={classes.button}>
                                Enregistrer
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
}

export default Ajouter;