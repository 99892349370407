import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getEtatCuisine } from '../../../../util';
import RadioGroupField from './../../../../components/form-controls/RadioGroupField';
import SelectField from './../../../../components/form-controls/SelectField';
import InputField from './../../../../components/form-controls/InputField';

EquipementSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
    onChange : PropTypes.func,
};

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

const LIST_CHOICE = [
    { name : 'terrace', label : 'Terrasse' },
    { name : 'balcony', label : 'Balcon' },
    { name : 'garden', label : 'Jardin privé' },
    { name : 'cellar', label : 'Cave' },
    { name : 'porch', label : 'Porche' },
];

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function EquipementSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled } = props;

    const ITEMS_KIT_STATE = getEtatCuisine();

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Equipement</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Etat cuisine</InputLabel>
                        <SelectField disabled={isDisabled} name="kitState" form={form} items={ITEMS_KIT_STATE} />
                    </Grid>
                    {
                        LIST_CHOICE.map((it, index) =>(
                            <Grid key={index} item xs={12}>
                                <RadioGroupField disabled={isDisabled} name={it.name} label={it.label} form={form} items={ITEMS_CHOICE} />
                            </Grid>
                        ))
                    }
                    <Typography variant="h6" className={classes.title}>Certificat de luminosité</Typography>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>URL du certificat de luminosité</InputLabel>
                     <InputField name="certLuminosite" form={form} pdfFile="true"/>
                     </Grid>
             <Typography variant="h6" className={classes.title}>Viste 360°</Typography>
                <Grid item xs={12}>
                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>URL de la visite 360°</InputLabel>
                    <InputField name="visite360Url" form={form}/>
                </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default EquipementSection;