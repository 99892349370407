import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import TextareaField from './../../../../components/form-controls/TextareaField';
import { InputType } from '../../../../components/form-controls/InputField/inputType';
import InputField from '../../../../components/form-controls/InputField';

ChargeTaxeSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function ChargeTaxeSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled } = props;

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Charges et taxes</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Charges globales</InputLabel>
                        <InputField disabled={isDisabled} name="globalCharges" form={form} type={InputType.PRICE} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Charges annuelles (Prop. + Loc.)</InputLabel>
                        <InputField disabled={isDisabled} name="monthlyCharge" form={form} type={InputType.PRICE} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Natures des charges</InputLabel>
                        <TextareaField disabled={isDisabled} name="chargeNature" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Taxe foncière</InputLabel>
                        <InputField disabled={isDisabled} name="propertyTax" form={form} type={InputType.PRICE} />
                    </Grid>
                    <Grid item xs={12}>
                       <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Taxe d&#39;habitation</InputLabel>
                        <InputField disabled={isDisabled} name="housingTax" form={form} type={InputType.PRICE} />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default ChargeTaxeSection;