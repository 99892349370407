import { Box, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TabPanel from './TabPanel';

TabHeader.propTypes = {
    menuList : PropTypes.array,
    actionAdd : PropTypes.any,
};

const useStyles = makeStyles((theme) => ({
    tab : {
        fontSize : '15px',
        minWidth : '100px',
        textTransform : 'none',
        color : '#272343',
        opacity : 1,
    },
    tabHeader : {
        borderBottom: '1px solid #dbdbdb',
        borderTop: '1px solid #dbdbdb',
    },
    headerItem : {
        padding : '0!important',
    },
    actionItem : {
        display : 'flex',
        justifyContent : 'flex-end',
        paddingRight : theme.spacing(2),
    }
}));

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabHeader(props) {

    const { menuList = [], actionAdd } = props;
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container spacing={2} className={classes.tabHeader}>
                <Grid item xs={8} className={classes.headerItem}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="icon tabs"
                        variant="scrollable"
                    >
                        {
                            menuList.map((item, index) => (
                                <Tab disabled={item.disabled} className={classes.tab} key={index} label={item.label} {...a11yProps(index)} />
                            ))
                        }
                    </Tabs>
                </Grid>
                <Grid item xs={4}>
                    <Box className={classes.actionItem}>{ !!actionAdd && actionAdd }</Box>
                </Grid>
            </Grid>
            {
                menuList.map((item, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        {item.component}
                    </TabPanel>
                ))
            }
        </>
    );
}

export default TabHeader;