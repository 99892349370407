import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { isAuthentificated, removeUserFromLocalStorage } from '../../../util';
import ForgetPwdForm from '../components/ForgetPwdForm';
import userApi from '../../../api/userApi';
import { ERROR_USER_HAS_DESACTIVE, ERROR_USER_NOT_EXISTE } from '../../../constants';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

ForgetPwdPage.propTypes = {
    location : PropTypes.any,
};

function ForgetPwdPage() {

    const { enqueueSnackbar } = useSnackbar();
    const isLogged = isAuthentificated();
    const [ isSubmitting, setSubmitting ] = useState(false);

    if (isLogged){
        removeUserFromLocalStorage();
    }

    const schema = yup.object().shape({
        email : yup.string().required('Veuillez saisir l\'email').email('Veuillez saisir un e-mail valide'),
    });

    const form = useForm({
        defaultValues: {
            email: '',
        },
        resolver : yupResolver(schema),
    });
    
    const handleSubmit = async (values, token) => {
        setSubmitting(true);
        try {
            await userApi.initpwd({ email: values.email, token });
            enqueueSnackbar('Un email vient d\'être envoyé pour la modification de votre mot de passe', { variant: 'success' });
        } catch (error) {
            const { code } = error;
            if (code === ERROR_USER_NOT_EXISTE){
                form.setError('email', 'Email n\'existe pas, veuillez vérifier');
                enqueueSnackbar('Email n\'existe pas, veuillez vérifier', { variant: 'error' });
            } else if (code === ERROR_USER_HAS_DESACTIVE){
                enqueueSnackbar('Ce compte est désactiver, veuillez demander l\'administrateur d\'activer le compte', { variant: 'error' });
            } else {
                enqueueSnackbar('Une erreur est survenue, veuillez réessayer ultérieurement.', { variant: 'error' });
            }
            console.log('fail to login', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ForgetPwdForm onSubmit={handleSubmit} form={form} isSubmitting={isSubmitting} />
    );
}

export default ForgetPwdPage;