import axios from 'axios';
import { ERROR_CODE_IDENTIFIANT, ERROR_CODE_EMAIL, ERROR_USER_NOT_EXISTE, ERROR_USER_HAS_ACTIVE, ERROR_USER_HAS_DESACTIVE, ERROR_USER_PWD_CONTAIN_FIRST_LAST_NAME } from '../constants/error-code';
import STORAGES_KEYS from '../constants/storages-keys';
import { logout } from '../util';
import authApi from './authApi';

const STATUS_ERROR_UNAUTHORIZED = 401;

const axiosClient = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials : true,
});

axiosClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(STORAGES_KEYS.TOKEN);

      if (token){
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => {
      return response.data;
    },
    async (error) => {
      const { config, response : { status, data } } = error;

      if (status === STATUS_ERROR_UNAUTHORIZED && !config._retry){

        const newConfig = { ...config, _retry : true };
        newConfig._retry = true;
        try {
          const token = await authApi.getNewToken();

          localStorage.setItem(STORAGES_KEYS.TOKEN, token);
          newConfig.headers.Authorization = `Bearer ${token}`;

          return axiosClient(newConfig);
        } catch (error) {
          logout();
          
          if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
          }

          return Promise.reject(error);
        }
      }

      if ([ ERROR_CODE_IDENTIFIANT, ERROR_CODE_EMAIL, ERROR_USER_NOT_EXISTE, ERROR_USER_HAS_ACTIVE, ERROR_USER_HAS_DESACTIVE, ERROR_USER_PWD_CONTAIN_FIRST_LAST_NAME ].includes(data.code)){
        return Promise.reject(data);
      }
  
      return Promise.reject(error);
    }
  );
  
  export default axiosClient;