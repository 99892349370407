import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Lotties from '../Lotties';
import NotFoundAnimation  from './../../assets/lotties/404.json';

NotFound.propTypes = {
};

const useStyles = makeStyles(() => ({
    typo : {
        textAlign : 'center',
    },
    link : {
        color : '#1b967c',
    }
}));

function NotFound() {

    const classes = useStyles();

    return (
        <Box>
            <Lotties animation={NotFoundAnimation} />
            <Typography className={classes.typo}><Link className={classes.link} href="/">{'Retour à la page d\'accueil'}</Link></Typography>
        </Box>
    );
}

export default NotFound;