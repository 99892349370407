import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import RadioGroupField from '../../../../components/form-controls/RadioGroupField';
import SelectField from '../../../../components/form-controls/SelectField';
import { afterDateDpe, is_local } from '../../../../util';
import { getStatusDpes } from '../../../../util/localstorageService';
import DateField from './../../../../components/form-controls/DateField';
import InputField from './../../../../components/form-controls/InputField';

DpeSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

function DpeSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled } = props;
    
    const bienInfo = useSelector((state) => state.bien);
    const [ valueSel, setValueSel ] = useState(bienInfo.dpeStat);
    const [ dateDpe, setDateDpe ] = useState(null);

    const handleChange = (e) => {
        setValueSel(e.target.value);
    };

    const handleChangeDate = (date) => {
        setDateDpe(date);
    };

    const ITEMS_STATUS_DPE = getStatusDpes();
    const STATUS_DPE_OBTENU = getStatusDpes().find(item => item.code === 'OBTENU')?.id;

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>DPE</Typography>
                <Grid container spacing={2}>
                    { is_local(bienInfo.type) && 
                        <Grid item xs={12}>
                            <RadioGroupField disabled={isDisabled} name="dpeMixedPerimeses" label="Local mixte" form={form} items={ITEMS_CHOICE} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Statut DPE</InputLabel>
                        <SelectField disabled={isDisabled} name="dpeStat" form={form} items={ITEMS_STATUS_DPE} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupField disabled={isDisabled} name="dpeVierge" label="DPE vierge" form={form} items={ITEMS_CHOICE} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupField disabled={isDisabled} name="dpeZoneMontagne" label="Zone montagne" form={form} items={ITEMS_CHOICE} />
                    </Grid>
                    { valueSel === STATUS_DPE_OBTENU && <>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" style={{ marginTop: 1 }} className={classes.labelStyle}>Date réalisation DPE</InputLabel>
                            <DateField disabled={isDisabled} name="dpeDate" form={form} handleChange={handleChangeDate} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Année de référence d&apos;estimation</InputLabel>
                            <InputField disabled={isDisabled} name="dpeAnneeRefPrixEnergie" form={form} />
                        </Grid>

                        { (dateDpe && !afterDateDpe(dateDpe)) && 
                            <Grid item xs={12}>
                                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Montant d&apos;estimation d&apos;énergie</InputLabel>
                                <InputField disabled={isDisabled} name="dpeMntEstimEnergie" form={form} />
                            </Grid>
                        }

                        { (dateDpe && afterDateDpe(dateDpe)) && <>
                            <Grid item xs={12}>
                                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Montant d&apos;estimation d&apos;énergie minimale</InputLabel>
                                <InputField disabled={isDisabled} name="dpeMntEstimEnergieMin" form={form} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Montant d&apos;estimation d&apos;énergie maximale</InputLabel>
                                <InputField disabled={isDisabled} name="dpeMntEstimEnergieMax" form={form} />
                            </Grid>
                        </>}

                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Valeur GES</InputLabel>
                            <InputField disabled={isDisabled} name="dpeGes" form={form} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Valeur KW</InputLabel>
                            <InputField disabled={isDisabled} name="dpeKw" form={form} />
                        </Grid>
                    </>}
                </Grid>
            </Paper>
        </Box>
    );
}

export default DpeSection;