import { HOUSING_TYPE_APPARTEMENT, HOUSING_TYPE_IMMEUBLE, HOUSING_TYPE_LOCAL_BUREAU, HOUSING_TYPE_MAISON, HOUSING_TYPE_STATIONNEMENT, HOUSING_TYPE_TERRAIN } from '../constants';

export const is_appartement_maison_immeuble = bienType => [ HOUSING_TYPE_APPARTEMENT, HOUSING_TYPE_MAISON, HOUSING_TYPE_IMMEUBLE ].includes(bienType);
export const is_appartement_maison_localBureau = bienType => [ HOUSING_TYPE_APPARTEMENT, HOUSING_TYPE_MAISON, HOUSING_TYPE_LOCAL_BUREAU ].includes(bienType);
export const is_appartement_maison = bienType => [ HOUSING_TYPE_APPARTEMENT, HOUSING_TYPE_MAISON ].includes(bienType);
export const is_immeuble = bienType => HOUSING_TYPE_IMMEUBLE === bienType;
export const is_local = bienType => HOUSING_TYPE_LOCAL_BUREAU === bienType;
export const is_stationnement = bienType => HOUSING_TYPE_STATIONNEMENT === bienType;
export const is_localBureau_immeuble = bienType => [ HOUSING_TYPE_IMMEUBLE, HOUSING_TYPE_LOCAL_BUREAU ].includes(bienType);
export const is_not_terrain = bienType => HOUSING_TYPE_TERRAIN !== bienType;
export const is_not_appartement = bienType => HOUSING_TYPE_APPARTEMENT !== bienType;
export const is_not_stationnement = bienType => HOUSING_TYPE_STATIONNEMENT !== bienType;
export const is_not_terrain_stationnement = bienType => HOUSING_TYPE_TERRAIN !== bienType && HOUSING_TYPE_STATIONNEMENT !== bienType;
export const is_not_immeuble_terrain = bienType => ![ HOUSING_TYPE_TERRAIN, HOUSING_TYPE_IMMEUBLE ].includes(bienType);
export const is_maison_immeuble = bienType => [ HOUSING_TYPE_MAISON, HOUSING_TYPE_LOCAL_BUREAU ].includes(bienType);