import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

TextareaField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
    root : {
        marginTop : 0,
        marginBottom : 0,
    }
}));

function TextareaField(props) {
    const { form, name, label, disabled } = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];
    const classes = useStyles();

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    label={label}
                    disabled={disabled}
                    error={!!hasError}
                    helperText={errors[name]?.message}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    multiline
                    minRows={2}
                    maxRows={4}
                    className={classes.root}
                />
            )}
        />
    );
}

export default TextareaField;