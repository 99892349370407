import React from 'react';
import useParam from '../../hook/useParam';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import NewPage from './pages/NewPage';
import EditPage from './pages/EditPage';

Franchise.propTypes = {
};

function Franchise() {

    const loadParams = useParam();
    const match = useRouteMatch();

    return (
        <>
            {
                loadParams && 
                <Switch>
                    <Route path={`${match.url}/new`} component={NewPage} />
                    <Route path={`${match.url}/:bienUuid`} component={EditPage} />
                </Switch>
            }
        </>
    );
}

export default Franchise;