import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/user';

const userApi = {
    async getRoles(){
        const url = `${BASE_URL}/roles`;
        return axiosClient.get(url);
    },
    async addUser(params){
        const url = `${BASE_URL}/new`;
        return axiosClient.put(url, params);
    },
    async listUser(params){
        const url = `${BASE_URL}/list`;
        return axiosClient.post(url, params);
    },
    async editUser(user){
        const url = `${BASE_URL}/edit`;
        return axiosClient.post(url, user);
    },
    async getProfile(){
        const url = `${BASE_URL}/profil`;
        return axiosClient.get(url);
    },
    async updateProfile(params){
        const url = `${BASE_URL}/profil`;
        return axiosClient.post(url, params);
    },
    async activeUser(identifiant){
        const url = `${BASE_URL}/${identifiant}/active`;
        return axiosClient.get(url);
    },
    async initpwd(params){
        const url = `${BASE_URL}/forgetPwd`;
        return axiosClient.post(url, params);
    },
    async desactiveUser(identifiant){
        const url = `${BASE_URL}/${identifiant}/desactive`;
        return axiosClient.post(url);
    },
};

export default userApi;