import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import RadioGroupField from './../../../../components/form-controls/RadioGroupField';

AnnexeSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
};

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    }
}));

function AnnexeSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled } = props;
    
    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Annexes</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RadioGroupField disabled={isDisabled} name="annexStudio" label="Studio annexe" form={form} items={ITEMS_CHOICE} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupField disabled={isDisabled} name="pool" label="Piscine" form={form} items={ITEMS_CHOICE} />
                    </Grid>
                </Grid>
            </Paper>
            
        </Box>
    );
}

export default AnnexeSection;