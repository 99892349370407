import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MultipleSelect from '../../../components/form-controls/MultipleSelect';
import { STATUS_BIEN_ARCHIVE, STATUS_BIEN_DIFFUSED, STATUS_BIEN_UNDIFFUSED } from '../../../constants';
import useTypeBien from '../../../hook/useTypeBien';

BienFilter.propTypes = {
    onChange : PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    selType : {
        marginLeft : theme.spacing(1),
    },
    items : {
        padding : theme.spacing(2),
    },
    item : {
        width : '100%',
    },
    labelStyle: {
        backgroundColor : 'white',
    },
    statutStyle: {
        backgroundColor : 'white',
        margin : '8px',
    }
}));

const FILTERS_FIELD = [
    { name : 'reference', label : 'Référence du bien' },
    { name : 'streetNumber', label : 'Adresse' },
    { name : 'zipCode', label : 'Code Postal' },
    { name : 'city', label : 'Ville' },
    { name : 'description', label : 'Mot clé' },
];
const BIEN_DIFFUSED = { value : STATUS_BIEN_DIFFUSED, label : 'Diffusé'};
const BIEN_NON_DIFFUSED = { value : STATUS_BIEN_UNDIFFUSED, label : 'Non diffusé'};
const BIEN_ARCHIVE = { value : STATUS_BIEN_ARCHIVE, label : 'Archivé'};
const STATUS_BIEN = [ BIEN_DIFFUSED, BIEN_NON_DIFFUSED, BIEN_ARCHIVE ];
const STATUS_BIEN_DEFAULT = [ BIEN_DIFFUSED, BIEN_NON_DIFFUSED, BIEN_ARCHIVE ];

const FILTER_STATUS = 'status';

function BienFilter(props) {

    const classes = useStyles();
    const { onChange } = props;
    const [ typeSel, setTypeSel ] = useState('');
    const typeBiens = useTypeBien();

    const handleSelectChange = (e) => {
        setTypeSel(e.target.value);
        handleChange(e);
    };

    const handleMultipleSelectChange = (e) => {
        handleChange({ target : { name : FILTER_STATUS, value : e.target.value.map(item => item.value) } });
    };

    const handleChange = (e) => {
        if (!onChange)  return;
        const { name, value } = e.target;
        onChange({ [name] : value });
    };

    return (
        <Grid container spacing={2} className={classes.items}>
            {
                FILTERS_FIELD.map((item, index) => (
                    <Grid key={index} item md={12} lg={6} className={classes.item}>
                        <TextField
                            id={`input-${item.name}`}
                            label={item.label}
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            name={item.name}
                            onChange={handleChange}
                            size="small"
                        />
                    </Grid>
                ))
            }
            {
                typeBiens && <Grid item md={12} lg={6} className={classes.item}>
                    <FormControl variant="outlined" fullWidth >
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}> Type du bien </InputLabel>
                        <Select className={classes.selType} labelId="select-label-type" id="select-type" onChange={handleSelectChange} value={typeSel} name="type" fullWidth variant="outlined" label=" Type du bien " displayEmpty>
                            <MenuItem value="">Tous les types</MenuItem>
                            {
                                typeBiens.map((item, index) => <MenuItem key={index} value={item.id}>{item.label}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            }
            <Grid item md={12} lg={6} className={classes.item}>
            <FormControl variant="outlined" fullWidth >
            <InputLabel shrink id="select-label-statut" className={classes.statutStyle}>Statut</InputLabel>
                <MultipleSelect name="status"  labelId="select-label-statut"  className={classes.selType} list={STATUS_BIEN} onChange={handleMultipleSelectChange}  fullWidth variant="outlined" listDefault={STATUS_BIEN_DEFAULT} />
           </FormControl>
            </Grid>
        </Grid>
    );
}

export default BienFilter;