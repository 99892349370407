import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useAdresse from '../../../../hook/useAdresse';
import useDebounce from '../../../../hook/useDebounce';
import AutoCompleteField from './../../../../components/form-controls/AutoCompleteField';
import InputField from './../../../../components/form-controls/InputField';
import RadioGroupField from './../../../../components/form-controls/RadioGroupField';

AdresseSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
};

const ITEMS_CHOICE = [
    { label : 'Oui', value : 'true' },
    { label : 'Non', value : 'false' },
];

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function AdresseSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled } = props;
    const bienInfo = useSelector((state) => state.bien);

    const [ inputValue, setInputValue ] = useState('');
    const debouncedSearchTerm = useDebounce(inputValue, 500);
    const adresses = useAdresse(debouncedSearchTerm) || [];

    const lst = adresses.map(item => ({ ...item.properties, value : item.properties.name, longitude : item.geometry.coordinates[0], latitude : item.geometry.coordinates[1] }));

    const handleChangeAdresse = (value) => {
        setInputValue(value);
    };

    const updateAutoComplete = (adresse) => {
        const { postcode, city, latitude, longitude } = adresse;
        form.setValue('zipCode', postcode);
        form.setValue('city', city);
        form.setValue('latitude', latitude);
        form.setValue('longitude', longitude);
    };

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Localisation</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Quartier</InputLabel>
                        <InputField disabled={isDisabled} name="hood" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Adresse *</InputLabel>
                        {
                            bienInfo.uuid 
                            ? <InputField disabled={true} name="streetNumber" form={form} /> 
                            : <AutoCompleteField name="streetNumber" form={form} list={lst} onChangeList={handleChangeAdresse} updateTrigger={updateAutoComplete} />
                        }
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Complément</InputLabel>
                        <InputField disabled={isDisabled} name="complement" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                            <RadioGroupField disabled={isDisabled} name="geoLocal" label="Géolocalisation Active" form={form} items={ITEMS_CHOICE} />
                        </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default AdresseSection;