// import PropTypes from 'prop-types';
import { Box, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bienApi from '../../../../api/bienApi';
import MenuMore from '../../../../components/MenuMore';
import Popup from '../../../../components/Popup';
import SliderSlick from '../../../../components/SliderSlick';
import { PHOTO_TYPE, URL_DOCUMENT_GET } from '../../../../constants';
import { setData } from '../../bienSlice';
import './PhotoList.css';

PhotoList.propTypes = {
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(4),
        fontWeight : 'bold',
        fontSize : theme.typography.h6.fontSize,
    },
    paper : {
        padding : theme.spacing(2),
    },
    iconBtn : {
        color : 'white',
    },
    itemBar : {
        backgroundColor : 'rgb(0 0 0 / 20%)'
    },
    list : {
        marginTop : theme.spacing(2),
        padding : theme.spacing(2),
    },
    title : {
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    imageItem : {
        borderRadius : '5px',
    }
}));

function PhotoList(props) {

    console.log(props);
    const classes = useStyles();

    const bienInfo = useSelector((state) => state.bien);
    const dispatch = useDispatch();
    const urlPhoto = localStorage.getItem(URL_DOCUMENT_GET);

    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    console.log(loading);

    const handleShow = (photo) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Visualiseur de photo',
            content : <><SliderSlick photos={bienInfo.photos} photoShow={photo} /></>,
            buttons : [
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const handleDelete = (photo) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Confirmation',
            content : <>Etes-vous sûr de vouloir supprimer cet photo ?</>,
            buttons : [
                {label : 'Supprimer', color : 'primary', onClick : () => deletePhoto(photo)},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const deletePhoto = async (photo) => {
        setLoading(true);
        try {
            const bien = await bienApi.deletePhoto(photo.id);
            enqueueSnackbar('Photo est supprimé avec succès', { variant: 'success' });
            const action = setData(bien);
            dispatch(action);
        } catch (error) {
            enqueueSnackbar('Une erreur est survenue lors de la suppression. Veuillez réessayer ultérieurement.', {variant: 'error'});
            console.log('fail to supprimant cet photo', error);
        } finally {
            resetState();
        }
    };

    const updatePhotoCover = async (photo) => {
        setLoading(true);
        try {
            const bien = await bienApi.updatePhotoCover(photo.id);
            enqueueSnackbar('Mise à jour avec succès', { variant: 'success' });
            const action = setData(bien);
            dispatch(action);
        } catch (error) {
            enqueueSnackbar('Une erreur est survenue en mise à jour la photo couverture. Veuillez réessayer ultérieurement.', {variant: 'error'});
            console.log('fail to mise à jour la photo couverture', error);
        } finally {
            resetState();
        }
    };

    const resetState = () => {
        setLoading(false);
        setOpenPopup(false);
        setParamPopup({});
    };

    const getOptions = (photo) => ([
        { title : 'Voir Photo', onClick : () => handleShow(photo), icon : VisibilityIcon, color: 'secondary', show : true },
        { title : 'Photo principale', onClick : () => updatePhotoCover(photo), icon : StarIcon, color: 'secondary', show : photo.type !== PHOTO_TYPE.COUVERTURE  },
        { title : 'Supprimer', onClick : () => handleDelete(photo), icon : DeleteIcon, color: 'error', show : true },
    ]);

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Toutes les photos</Typography>
                { (bienInfo.photos && bienInfo.photos.length)
                ? <Grid container spacing={1} className={classes.list}>
                    <Grid xs={12} item>
                        <ImageList gap={16} cols={4}>
                            {bienInfo.photos.map((item, index) => (
                                <ImageListItem key={index}>
                                    <img src={`${urlPhoto}${item.path}?s=m`} srcSet={`${urlPhoto}${item.path}?s=m`} alt={item.type} loading="lazy" className={classes.imageItem}/>
                                    <ImageListItemBar
                                        className={classes.itemBar}
                                        title={item.title}
                                        position="top"
                                        actionIcon={
                                            <Box marginLeft={1} display="flex" justifyContent="center" alignItems="center">
                                                <MenuMore options={getOptions(item)} key={index} colorIcon="white" width='30ch' />
                                                { (item.type === PHOTO_TYPE.COUVERTURE) &&
                                                    <Tooltip title="Photo couverture">
                                                        <IconButton className={classes.iconBtn}>
                                                            <StarIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    }
                                            </Box>
                                        }
                                        actionPosition="left"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Grid>
                </Grid>
                : <Box textAlign="center"><Typography variant="subtitle2">Aucune photo téléchargé</Typography></Box>
            }
            </Paper>
            <Popup open={openPopup} {...paramPopup} />
        </Box>
    );
}

export default PhotoList;