import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#f25042',
    },
    background: {
      default: '#fff',
    },
    headline: {
      main: '#272343',
    },
    paragraph: {
      main: '#2d334a',
    },
    btnText : {
      main: '#272343',
    }
  },
});

export default theme;