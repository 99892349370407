import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table, Paper, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import userApi from '../../../../api/userApi';
import Popup from '../../../../components/Popup';
import UserFormModif from './UserFormModif';
import UserItem from './UserItem';
import { ERROR_CODE_EMAIL } from './../../../../constants';
import { isSuperAdmin } from '../../../../util';

UserList.propTypes = {
    data : PropTypes.array,
    callback : PropTypes.func,
};

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
    },
    center : {
        textAlign : 'center',
    },
    bold : {
        fontWeight : 'bold',
    }
}));

function UserList(props) {
    const { data = [], callback } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(true);

    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});

    const isSuperAdm = isSuperAdmin();

    console.log(loading);

    const handleModifyUser = (user) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Modifier l\'utilisateur',
            form : <UserFormModif user={user} onSubmit={submitModifyUser} />,
            content : <>Information de <b>{user.username}</b></>,
            buttons : [
                {label : <label htmlFor='form-submit'>Modifier</label>, color : 'primary'},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ],
        });
    };

    const submitModifyUser = async (user) => {
        setLoading(true);
        try {
            ['agence', 'cabinet', 'roles'].forEach(item => delete user[item]);
            await userApi.editUser(user);
            enqueueSnackbar('Modification avec succès', { variant: 'success' });

            if (callback)    callback();
        } catch (error) {
            const { code } = error;
            const msg = (code && code === ERROR_CODE_EMAIL) ? 'Email est déjà utilisé par un autre utilisateur' : 'Une erreur est survenue, veuillez réessayer ultérieurement.';
            enqueueSnackbar(msg, { variant: 'error' });
            console.log(msg, error);
        } finally {
            resetState();
        }
    };

    const handleActiveUser = async (user) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Activer utilisateur',
            content : <>Etes-vous sûr de vouloir activer <b>{user.username}</b> ? Un email va être envoyer à cet utilisateur pour lui demander de changer le mot de passe</>,
            buttons : [
                {label : 'Activer', color : 'primary', onClick : () => activeUser(user)},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const activeUser = async (user) => {
        setLoading(true);
        try {
            await userApi.activeUser(user.username);
            enqueueSnackbar('Un email a envoyé au utilisateur.', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Une erreur est survenue en activant cet utilisateur. Veuillez réessayer ultérieurement.', {variant: 'error'});
            console.log('fail to activer user', error);
        } finally {
            resetState();
        }
    };

    const handleDesactiveUser = async (user) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Activer utilisateur',
            content : <>Etes-vous sûr de vouloir désactiver <b>{user.username}</b> ?</>,
            buttons : [
                {label : 'Désactiver', color : 'primary', onClick : () => desactiveUser(user)},
                {label : 'Annuler', color : 'default', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const desactiveUser = async (user) => {
        setLoading(true);
        try {
            await userApi.desactiveUser(user.username);
            enqueueSnackbar('Désactiver avec succès', { variant: 'success' });

            if (callback)    callback();
        } catch (error) {
            enqueueSnackbar('Une erreur est survenue en désactivant cet utilisateur. Veuillez réessayer ultérieurement.', { variant: 'error' });
            console.log('fail to désactiver user', error);
        } finally {
            resetState();
        }
    };

    const resetState = () => {
        setLoading(false);
        setOpenPopup(false);
        setParamPopup({});
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.bold}>Nom</TableCell>
                            <TableCell className={classes.bold}>Prénom</TableCell>
                            <TableCell className={classes.bold}>Mail</TableCell>
                            <TableCell className={classes.bold}>Identifiant</TableCell>
                            { isSuperAdm && <TableCell className={classes.bold}>Agence</TableCell> }
                            <TableCell className={classes.bold}>Rôle</TableCell>
                            <TableCell className={classes.bold}>Statut</TableCell>
                            <TableCell align="center" className={classes.bold}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Boolean(data.length) && data.map((user, index) => (
                                <UserItem key={index} user={user} onModify={handleModifyUser} onActiver={handleActiveUser} onDesactive={handleDesactiveUser} />
                            ))
                        }
                        {
                            Boolean(!data.length) && (
                                <TableRow hover >
                                    <TableCell colSpan={6} className={classes.center}>Aucune utilisateur trouvé</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Popup
                open={openPopup} 
                {...paramPopup}
            />
        </>
    );
}

export default UserList;