import { Avatar, Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { ROLES_CODES } from '../../../constants';

UserProfile.propTypes = {
    user : PropTypes.object,
};

const useStyles = makeStyles(() => ({
    box : {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    avatar : {
        width : '100px',
        height : '100px',
    },
}));

function UserProfile (props) {

    const { user } = props;
    const classes = useStyles();
    const showRole = [ ROLES_CODES.AGENCE_ADM, ROLES_CODES.SUPER_ADM ].includes(user.roles[0].code);

    return (
    <Card>
      <CardContent>
        <Box className={classes.box}>
            <Avatar src={user.avatar} className={classes.avatar} />
            <Typography color="textPrimary" gutterBottom variant="h6">{user.firstName} {user.lastName}</Typography>
            {
              showRole && <Typography color="secondary" variant="body1">{user.roles[0].label}</Typography>
            }
            <Typography color="textSecondary" variant="body1">Cabinet : {user.cabinet.label}</Typography>
            {
              user.agence && <Typography color="textSecondary" variant="body1">Agence : {user.agence.label}</Typography>
            }
        </Box>
      </CardContent>
    </Card>
  );
}

export default UserProfile;
