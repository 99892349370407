import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import InputField from './../../../../components/form-controls/InputField';
import { is_not_terrain_stationnement, is_not_appartement } from '../../../../util';

SurfaceDimensionSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
    bienType : PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function SurfaceDimensionSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled, bienType } = props;

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Surfaces et dimensions</Typography>
                <Grid container spacing={2}>
                    { is_not_terrain_stationnement(bienType) && <>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Habitable (m²)</InputLabel>
                            <InputField disabled={isDisabled} name="habitable" form={form} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Terrain (m²)</InputLabel>
                            <InputField disabled={isDisabled} name="terrain" form={form} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Carrez (m²)</InputLabel>
                            <InputField disabled={isDisabled} name="carrez" form={form} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Séjour (m²)</InputLabel>
                            <InputField disabled={isDisabled} name="sejour" form={form} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Surface du plancher</InputLabel>
                            <InputField disabled={isDisabled} name="plancherSurface" form={form} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Hauteur sous-plafond</InputLabel>
                            <InputField disabled={isDisabled} name="roofHeight" form={form} />
                        </Grid>
                    </>}
                    { is_not_appartement(bienType) && 
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Surface totale</InputLabel>
                            <InputField disabled={isDisabled} name="totalGroundSurface" form={form} />
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Box>
    );
}

export default SurfaceDimensionSection;
