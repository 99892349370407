import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import userApi from '../api/userApi';

export default function(){
  const [ roles, setRoles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        const res = await userApi.getRoles() || [];
        const roleConvert = res.map(item => ({...item, value : item.code}));
        setRoles(roleConvert);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ne peut pas télécharger la liste de rôle', { variant: 'error' });
      }
    })();
  }, []);

  return roles;
}