import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Container, CssBaseline, LinearProgress, makeStyles, Paper, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import InputField from '../../../../components/form-controls/InputField';
import PasswordField from '../../../../components/form-controls/PasswordField';
import useCaptcha from '../../../../hook/useCaptcha';
import logo from './../../../../assets/images/Foncia_logoh_66_66.png';

UpdateForm.propTypes = {
    onSubmit: PropTypes.func,
    identifiant : PropTypes.string,
    email : PropTypes.string,
    isSubmitting: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
    root : {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit : {
        margin : theme.spacing(3, 0, 2, 0),
    },
    progress : {
        position : 'absolute',
        top : theme.spacing(1),
        left : 0,
        right: 0,
    },
    paper : {
        marginTop : theme.spacing(6),
        padding : theme.spacing(3),
    },
    form : {
        marginTop : theme.spacing(6)
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function UpdateForm(props) {
    const { onSubmit, identifiant, email, isSubmitting } = props;
    const classes = useStyles();
    const captcha = useCaptcha();

    const schema = yup.object().shape({
        password : yup.string().required('Veuillez entrer le mot de passe').matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Ce champs doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et une casse spéciale'
        ),
        retypePassword : yup.string().required('Veuillez retaper le mot de passe').oneOf([yup.ref('password')], 'Le mot de passe ne correspond pas'),
    });

    const form = useForm({
        defaultValues: {
            username : identifiant || '',
            email : email || '',
            password: '',
            retypePassword: '',
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(captcha, { action: 'submit' });
                onSubmit && onSubmit(values, token);
            } catch (error) {
                console.log(error);
            }
        });
    };

    return (<>
        {
            captcha && 
            <Container component="main" maxWidth="xs">
                <Paper elevation={1} className={classes.paper}>
                    <CssBaseline />
                    <div className={classes.root}>
                        { isSubmitting && <LinearProgress className={classes.progress} /> }
                        <Box><img src={logo} alt="Les franchisés" /></Box>
                        <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.form}>
                            <div style={{marginBottom : '15px'}}>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Identifiant</InputLabel>
                            <InputField name="username" form={form} disabled={true} className={classes.item}/>
                            </div>
                            <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Email</InputLabel>
                            <InputField name="email" form={form} disabled={true} style={{marginTop:'11px'}}/>
                            
                            <PasswordField name="password" label="Password *" form={form} />
                            <PasswordField name="retypePassword" label="Confirmer le mot de passe *" form={form} />

                            <Button disabled={isSubmitting} type="submit" className={classes.submit} variant='contained' color='primary' fullWidth size="medium">
                                Mettre à jour
                            </Button>
                        </form>
                    </div>
                </Paper>
            </Container>
        }
    </>);
}

export default UpdateForm;
