import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import DateField from '../../../../components/form-controls/DateField';
import TextareaField from '../../../../components/form-controls/TextareaField';
import { getEtatGeneral, getTypeBiens, getTypeMandat, is_appartement_maison_localBureau, is_immeuble, is_localBureau_immeuble, is_not_terrain, is_not_terrain_stationnement } from '../../../../util';
import InputField from './../../../../components/form-controls/InputField';
import SelectField from './../../../../components/form-controls/SelectField';

GeneralSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
    onChange : PropTypes.func,
    bienType : PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));

function GeneralSection(props) {

    const classes = useStyles();
    const { form, hasValide : isDisabled, bienType, onChange } = props;
    const bienInfo = useSelector((state) => state.bien);

    const ITEMS_TYPE_BIEN = getTypeBiens();
    const ITEMS_GLOBAL_STATE = getEtatGeneral();
    const ITEMS_TYPE_MANDAT = getTypeMandat();

    const handleChange = (e) => {
        onChange && onChange(e.target.value);
    };

    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Informations Générales</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Titre de l&#39;annonce</InputLabel>
                        <InputField disabled={isDisabled} name="libelle" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Type de bien *</InputLabel>
                        <SelectField disabled={!!bienInfo.uuid} name="type" form={form} items={ITEMS_TYPE_BIEN} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {'(attention suite à l\'enregistrement, le type de bien ne pourra plus être modifié)'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Type mandat</InputLabel>
                            <SelectField disabled={isDisabled} name="typeMandat" form={form} items={ITEMS_TYPE_MANDAT} />
                        </Grid>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Surface (m²)</InputLabel>
                        <InputField disabled={isDisabled} name="surface" form={form} />
                    </Grid>
                    { is_not_terrain_stationnement(bienType) && <>
                        <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Etat général</InputLabel>
                            <SelectField disabled={isDisabled} name="glState" form={form} items={ITEMS_GLOBAL_STATE} />
                        </Grid>
                        <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de pièces</InputLabel>
                            <InputField disabled={isDisabled} name="roomCount" form={form} />
                        </Grid>
                    </>}
                    { is_appartement_maison_localBureau(bienType) && <>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de salle de bain</InputLabel>
                        <InputField disabled={isDisabled} name="bathroomCount" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de salle d&#39;eau</InputLabel>
                        <InputField disabled={isDisabled} name="showerroomCount" form={form} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de WC</InputLabel>
                        <InputField disabled={isDisabled} name="wcCount" form={form} />
                    </Grid>
                    </>}
                    {is_localBureau_immeuble(bienType) && 
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de locaux commerciaux</InputLabel>
                        <InputField disabled={isDisabled} name="commercialPerimises" form={form} />
                    </Grid>
                    }
                    {
                        is_immeuble(bienType) && <>
                            <Grid item xs={12}>
                                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de logement</InputLabel>
                                <InputField disabled={isDisabled} name="appartmentCount" form={form} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de logement occupé</InputLabel>
                                <InputField disabled={isDisabled} name="occupiedAppartmentCount" form={form} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de logement vide</InputLabel>
                                <InputField disabled={isDisabled} name="emptyAppartmentCount" form={form} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de bâtiment</InputLabel>
                                <InputField disabled={isDisabled} name="buildingCount" form={form} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Nombre de cave</InputLabel>
                                <InputField disabled={isDisabled} name="cellarCount" form={form} />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Texte publicitaire</InputLabel>
                        <TextareaField disabled={isDisabled} name="description" form={form} />
                    </Grid>
                    { is_not_terrain(bienType) && 
                        <Grid item xs={12}>
                            <InputLabel shrink id="select-label-type"  style={{ marginTop: 1 }} className={classes.labelStyle}>Date de libération</InputLabel>
                            <DateField disabled={isDisabled} name="releaseDate" form={form} />
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Box>
    );
}

export default GeneralSection;
