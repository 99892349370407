import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

Popup.propTypes = {
    title : PropTypes.string,
    content : PropTypes.any,
    form : PropTypes.any,
    buttons : PropTypes.array,
    open : PropTypes.bool,
};

const useStyles = makeStyles(() => ({
    button : {
        textTransform : 'none',
    }
}));

function Popup(props) {

    const { title, content, form, buttons, open } = props;
    const classes = useStyles();

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                { content && <DialogContentText>{content}</DialogContentText> }
                { form && <>{form}</> }
            </DialogContent>
            <DialogActions>
                {
                    buttons && buttons.map((item, index) => (
                        <Button 
                            key={index} 
                            onClick={item.onClick} 
                            color={item.color} 
                            className={classes.button}
                        >
                            {item.label}
                        </Button>
                    ))
                }
            </DialogActions>
        </Dialog>
    );
}

export default Popup;