import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import userApi from '../../api/userApi';
import UserProfile from './components/UserProfile';
import UserProfileDetail from './components/UserProfileDetail';
// import PropTypes from 'prop-types';

Profile.propTypes = {
};

const useStyles = makeStyles((theme) => ({
    root : {
        minHeight : '100%',
        paddingTop : theme.spacing(3),
        paddingBottom : theme.spacing(3),
    },
    paper : {
        padding : theme.spacing(3),
    }
}));

function Profile() {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [ user, setUser ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    console.log(loading);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const res = await userApi.getProfile();
                setUser(res);
            } catch (error) {
                enqueueSnackbar('Échec de l\'obtention du profil', { variant: 'error' });
                console.log('Échec de l\'obtention du profil', error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        user && (<>
                            <Grid item lg={4} md={6} xs={12}>
                                <UserProfile user={user} />
                            </Grid>
                            <Grid item lg={8} md={6} xs={12}>
                                <UserProfileDetail user={user} />
                            </Grid>
                        </>)
                    }
                </Grid>
            </Container>
        </Box>
    );
}

export default Profile;