import { Box, Grid, makeStyles, Paper, Typography, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { InputType } from '../../../../components/form-controls/InputField/inputType';
import InputField from '../../../../components/form-controls/InputField';

EstimationSection.propTypes = {
    form : PropTypes.any,
    hasValide : PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(2),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    paper : {
        padding : theme.spacing(2),
    },
    title : {
        marginBottom : theme.spacing(2),
        fontWeight : 'bold',
        color : theme.palette.headline.main,
    },
    labelStyle: {
        backgroundColor : 'white',
        position: 'absolute',
        marginTop: '-8px',
        marginLeft: '5px',
        zIndex: '1',
        textIndent: '4px',
        paddingRight: '7px'
    }
}));



function EstimationSection(props) {
    const classes = useStyles();
    const { form, hasValide : isDisabled } = props;

    const handleHonoraryChange = (event) => {
        const ratio = 1.2;
        const value = event?.target?.value ?? event;
        // Check if it is a numeric character
        if (value && !Number.isNaN(Number(`${value}`.replace(/\s/g, '')))) {
            // Format value to a currency format and remove the currency symbol at the end, divide by ratio to have the honorary value
            let result = new Intl.NumberFormat().format(Number(`${value}`.replace(/\s/g, '')) / ratio).replace(/,\d+/, '');
            // Check if there is decimal
            if (value.toString().indexOf('.') >= 0) {
                const decimal = value.toString().slice(value.toString().indexOf('.'));
                // Add the decimal, divide by ratio to have the honorary value, and fixed to 2 decimal
                result += `${!isNaN(decimal) ? (Number(decimal) / ratio).toFixed(2) : ''}`.replace('0', '');
            }
            // Update value to form and input
            document.getElementById('honoraryHt').value = result ?? '';
            form.setValue('honoraryHt', result ?? '');
        }
    };
    
    return (
        <Box className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Prix de vente </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Prix de vente (frais d&apos;agence inclus)</InputLabel>
                        <InputField disabled={isDisabled} name="price" form={form} type={InputType.PRICE} />
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Honoraires</InputLabel>
                        <InputField disabled={isDisabled} name="honorary" form={form} type={InputType.PRICE} onChange={handleHonoraryChange} />
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Honoraires HT</InputLabel>
                        <InputField disabled="true" id="honoraryHt" name="honoraryHt" type={InputType.PRICE} form={form} />
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel shrink id="select-label-type" className={classes.labelStyle}>Loyer en cours</InputLabel>
                        <InputField name="loyer" form={form} type={InputType.PRICE} />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default EstimationSection;