import STORAGES_KEYS, { ROLES_CODES } from '../constants/storages-keys';
import jwt from 'jwt-decode';
import authApi from '../api/authApi';

const isAuthentificated = () => {
    try {
        const token = localStorage.getItem(STORAGES_KEYS.TOKEN);
        if (!token)  return false;

        const infoToken = jwt(token);
        if (infoToken.exp > Date.now() / 1000)   return true;
        getNewToken();
        return true;
    } catch (error) {
        console.log('Une erreur est survenue ' , error);
        return false;
    }
};

const getNewToken = async () => {
    try {
        const newToken = await authApi.getNewToken();
        localStorage.setItem(STORAGES_KEYS.TOKEN, newToken);
    } catch (error) {
        console.log('Votre session est expiré ' , error);
        logout();
    }
};

const isAgenceAdmin = () => {
    const isLogged = isAuthentificated();
    if (!isLogged)   return false;

    const user = getUserInfo();
    return user && user.roles && user.roles.map(role => role.code).includes(ROLES_CODES.AGENCE_ADM);
};

const isSuperAdmin = () => {
    const isLogged = isAuthentificated();
    if (!isLogged)   return false;

    const user = getUserInfo();
    return user && user.roles && user.roles.map(role => role.code).includes(ROLES_CODES.SUPER_ADM);
};

const isAdmin = () => {
    const isLogged = isAuthentificated();
    if (!isLogged)   return false;

    const user = getUserInfo();
    return user && user.roles && user.roles.map(role => role.code).some(code => [ROLES_CODES.AGENCE_ADM, ROLES_CODES.SUPER_ADM].includes(code));
};

const getUserInfo = () => {
    return JSON.parse(localStorage.getItem(STORAGES_KEYS.USER));
};

const getToken = () => {
    return localStorage.getItem(STORAGES_KEYS.TOKEN);
};

const removeUserFromLocalStorage = () => {
    localStorage.removeItem(STORAGES_KEYS.USER);
    localStorage.removeItem(STORAGES_KEYS.TOKEN);
};

const logout = () => {
    removeUserFromLocalStorage();
    window.location.href = '/auth/login';
};

export { isAuthentificated, isAdmin, logout, getUserInfo, getToken, removeUserFromLocalStorage, isAgenceAdmin, isSuperAdmin };