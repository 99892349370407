import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

DateField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    handleChange : PropTypes.func,
};

function DateField(props) {

    const { form, name, label, disabled, handleChange } = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, onBlur, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="dense"
                        id={name}
                        label={label}
                        value={value}
                        onChange={(date)=>{
                            if (handleChange)   handleChange(date);
                            onChange(date);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        onBlur={onBlur}
                        disabled={disabled}
                        error={!!hasError}
                        inputVariant="outlined"
                        fullWidth
                    />
                </MuiPickersUtilsProvider>
            )}
        />
    );
}

export default DateField;