import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import axiosClient from '../api/axiosClient';
import otherApi from '../api/otherApi';
import { URL_DOCUMENT_GET } from '../constants';

export default function(){
  const [ conf, setConf ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        const res = await otherApi.getConfig();
        axiosClient.defaults.baseURL = res.data.API_END_POINT;
        localStorage.setItem(URL_DOCUMENT_GET, res.data.URL_DOCUMENT_GET);
        setConf(res);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ne peut pas télécharger la configuration endpoint', { variant: 'error' });
      }
    })();
  }, []);

  return conf;
}