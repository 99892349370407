import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

FileField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChange : PropTypes.func
};

const useStyles = makeStyles(() => ({
    button : {
        textTransform : 'none',
    }
}));

function FileField(props) {
    const { form, name, label, disabled, onChange } = props;

    const idInput = `contained-button-file-${name}`;
    const classes = useStyles();

    const handleChange = (event) => {
        if (!onChange)  return;
        onChange(event);
    };

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, onBlur, name } }) => (
                <>
                    <input
                        style={{ display: 'none' }}
                        id={idInput}
                        type="file"
                        disabled={disabled}
                        onChange={(e) => {
                            handleChange(e);
                            onChange(e);
                        }}
                        onBlur={onBlur}
                        name={name}
                        accept="image/gif, image/jpeg, image/jpg, image/png"
                    />
                    <label htmlFor={idInput}>
                        <Button variant="contained" color="primary" component="span" className={classes.button}>
                            {label}
                        </Button>
                    </label>
                </>
            )}
        />
    );
}

export default FileField;