import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/bien';

const bienApi = {
    async create(params){
        const url = `${BASE_URL}/create`;
        return axiosClient.post(url, params);
    },
    async update(uuid, params){
        const url = `${BASE_URL}/update/${uuid}`;
        return axiosClient.post(url, params);
    },
    async get(uuid){
        const url = `${BASE_URL}/${uuid}`;
        return axiosClient.get(url);
    },
    async list(params){
        const url = `${BASE_URL}/list`;
        return axiosClient.post(url, params);
    },
    async delete(bienUuid){
        const url = `${BASE_URL}/${bienUuid}`;
        return axiosClient.delete(url);
    },
    async diffuse(bienUuid){
        const url = `${BASE_URL}/diffuse/${bienUuid}`;
        return axiosClient.post(url);
    },
    async archiver(bienUuid){
        const url = `${BASE_URL}/archiver/${bienUuid}`;
        return axiosClient.post(url);
    },
    async undiffuser(bienUuid){
        const url = `${BASE_URL}/undiffuser/${bienUuid}`;
        return axiosClient.post(url);
    },
    async uploadPhoto(bienUuid, typeDoc, params){
        const url = `${BASE_URL}/photo/upload/${bienUuid}/${typeDoc}`;
        return axiosClient.post(url, params, { headers: { 'Content-Type': 'multipart/form-data' } });
    },
    async deletePhoto(docId){
        const url = `${BASE_URL}/photo/delete/${docId}`;
        return axiosClient.delete(url);
    },
    async updatePhotoCover(docId){
        const url = `${BASE_URL}/photo/update/${docId}`;
        return axiosClient.post(url);
    },
};

export default bienApi;