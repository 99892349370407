import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import UserFilter from './components/UserFilter';
import UserList from './components/UserList';
import UserSkeletonList from './components/UserSkeletonList';
import userApi from './../../../api/userApi';
// import PropTypes from 'prop-types';

Recherche.propTypes = {
    
};

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop : theme.spacing(5),
        fontWeight : 'bold',
        fontSize : '20px',
    },
    pagination : {
      display : 'flex',
      flexFlow : 'row nowrap',
      justifyContent : 'center',

      marginTop : '20px',
      paddingBottom : '10px',
    },
    boxButton : {
        marginRight : theme.spacing(6),
        display : 'flex',
        justifyContent : 'flex-end',
    },
    paper : {
        padding : theme.spacing(2),
    },
    button : {
        textTransform : 'none',
    },
    title : {
        fontWeight : 'bold',
    }
}));

function Recherche() {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [ userList, setUserList ] = useState([]);

    const [ pagination, setPagination ] = useState({ page : 0, count : 1 });
    const [ loading, setLoading ] = useState(true);
    const [ filters, setFilters ] = useState(() => ({ page : 0, size : 3, params : {} }));

    const [ formValue, setFormValue ] = useState({ identifiant : '', firstName : '', lastName : '', email : '' });

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFormValue({ ...formValue, [name] : value });
    };

    const handlePageChange = (e, page) => {
        setFilters(prevFilter => ({...prevFilter, page : page - 1 }));
    };

    const handleClick = async () => {
        setFilters(prevFilter => ({...prevFilter, page : 0, params : { ...formValue }}));
    };

    useEffect(() => {
        getListUser();
    }, [ filters ]);

    const getListUser = async () => {
        setLoading(true);
        try {
            const { items, totalPages, currentPage } = await userApi.listUser(filters);
            setUserList(items);
            setPagination({ count : totalPages, page : currentPage + 1 });
        } catch (error) {
            enqueueSnackbar('Échec de l\'obtention de l\'utilisateur de la liste', {variant: 'error'});
            console.log('Échec de l\'obtention de l\'utilisateur de la liste', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box className={classes.root}>
                        <Paper elevation={1} className={classes.paper}>
                            <Typography variant="h6" className={classes.title}>{'Rechercher un utilisateur'}</Typography>
                            <UserFilter filters={filters} onChange={handleFilterChange} />
                            <Box className={classes.boxButton}>
                                <Button onClick={handleClick} variant="contained" color="primary" className={classes.button}>
                                    Lancer le recherche
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Paper elevation={1} className={classes.paper}>
                        {loading 
                        ? <UserSkeletonList length={9} /> 
                        : <>
                            <UserList data={userList} callback={getListUser} />
                            <Box className={classes.pagination} >
                                <Pagination color="primary" count={pagination.count} page={pagination.page} onChange={handlePageChange} />
                            </Box>
                        </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Recherche;