import React from 'react';
import PhotoAdd from './PhotoAdd';
import PhotoList from './PhotoList';

Photo.propTypes = {
};

function Photo() {

    return (<>
        <PhotoAdd />
        <PhotoList />
    </>);
}

export default Photo;