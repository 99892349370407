import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import ForgetPwdPage from './pages/ForgetPwdPage';
import LoginPage from './pages/LoginPage';
import UpdatePage from './pages/UpdatePage';
import UpdatePwdPage from './pages/UpdatePwdPage';

Auth.propTypes = {
    
};

function Auth() {

    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.url}/login`} component={LoginPage} />
            <Route exact path={`${match.url}/update`} component={UpdatePage} />
            <Route exact path={`${match.url}/updatepwd`} component={UpdatePwdPage} />
            <Route exact path={`${match.url}/initpwd`} component={ForgetPwdPage} />
        </Switch>
    );
}

export default Auth;