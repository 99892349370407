import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/params';

const paramApi = {
    async typeBiens(){
        const url = `${BASE_URL}/typeBiens`;
        return axiosClient.get(url);
    },
    async agence(){
        const url = `${BASE_URL}/agences`;
        return axiosClient.get(url);
    },
    async keyCaptcha(){
        const url = `${BASE_URL}/captcha`;
        return axiosClient.get(url);
    },
    async paramOption(){
        const url = `${BASE_URL}/paramOption`;
        return axiosClient.get(url);
    },
};

export default paramApi;