import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/Router/PrivateRoute';
import Auth from './features/Auth';
import useConfig from './hook/useConfig';

function App() {

  const config = useConfig();

  return (
    <>
      {
        config && 
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route component={PrivateRoute} />
        </Switch>
      }
    </>
  );
}

export default App;
