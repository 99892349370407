import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

RadioGroupField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    items : PropTypes.array,
};

function RadioGroupField(props) {

    const { form, name, label, items, disabled } = props;
    // const [valueSelected, setValueSelected] = useState(null);

    // const handleChange = (event) => {
    //     setValueSelected(event.target.value);
    // };

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, name, value } }) => (
                <FormControl component="fieldset">
                    <FormLabel component="legend">{label}</FormLabel>
                    <RadioGroup row aria-label="gender" name={name} value={`${value}`} onChange={(e)=>{
                        // handleChange(e);
                        onChange(e);
                    }}>
                        {
                            items.map((item, index) => (
                                <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} disabled={disabled} />
                            ))
                        }
                    </RadioGroup>
                </FormControl>
            )}
        />
    );
}

export default RadioGroupField;