import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/auth';

const authApi = {
    async signIn(params){
        const url = `${BASE_URL}/login`;
        return axiosClient.post(url, params);
    },
    async getNewToken(){
        const url = `${BASE_URL}/refreshtoken`;
        return axiosClient.get(url);
    },
    async logout(){
        const url = `${BASE_URL}/logout`;
        return axiosClient.get(url);
    },
    async update(identifiant, params){
        const url = `${BASE_URL}/update/${identifiant}`;
        return axiosClient.post(url, params);
    },
    async updatePwd(identifiant, params){
        const url = `${BASE_URL}/updateForgetPwd/${identifiant}`;
        return axiosClient.post(url, params);
    },
};

export default authApi;